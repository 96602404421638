import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  LinearProgress,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { UserPlan } from '@/types/AgentTypes';

const UserPlanDisplay: React.FC<UserPlan> = ({
  planName,
  planFeatures,
  agentsCreated,
  agentLimit,
}) => {
  const progress = (agentsCreated / agentLimit) * 100;

  return (
    <Box sx={{ padding: 3, borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h6" gutterBottom>
        {planName} Plan
      </Typography>
      <List dense>
        {planFeatures.map((feature) => (
          <ListItem key={feature}>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={feature} />
          </ListItem>
        ))}
      </List>
      <Typography variant="body1" gutterBottom>
        Agents Created: {agentsCreated} / {agentLimit}
      </Typography>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
};

export default UserPlanDisplay;
