import React from 'react';
import { Stepper, Step, StepLabel, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

export const steps = [
  'Select Template',
  'Basic Info',
  'Voice Selection',
  'Skill Building',
  'Review Agent',
  'Create Agent',
  'Phone Number Assignment',
  'Complete Setup'
];

const StyledStepper = styled(Stepper)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: theme.spacing(2, 0),
  '& .MuiStepLabel-root .Mui-completed': {
    color: theme.palette.success.main,
  },
  '& .MuiStepLabel-root .Mui-active': {
    color: theme.palette.primary.main,
  },
}));

interface ProgressTrackerProps {
  activeStep: number;
}

const ProgressTracker: React.FC<ProgressTrackerProps> = ({ activeStep }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledStepper activeStep={activeStep} alternativeLabel={!isMobile} orientation={isMobile ? 'vertical' : 'horizontal'}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </StyledStepper>
  );
};

export default ProgressTracker;