import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SidebarList from './SidebarList';
import { Assistant, fetchUserAssistants, updateAssistantProfileImage } from '@/api/api';

export const SidebarListWrapper: React.FC = () => {
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loadAssistants = async () => {
      try {
        const userAssistants = await fetchUserAssistants();
        setAssistants(userAssistants);
      } catch (error) {
        console.error('Error fetching assistants:', error);
      }
    };
    loadAssistants();
  }, []);

  const handleAssistantClick = (assistantId: string) => {
    navigate(`/agent/${assistantId}`);
  };

  const handleInitialAssistantClick = (assistantId: string) => {
    if (location.pathname === '/') {
      navigate(`/agent/${assistantId}`);
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleImageUpload = async (assistantId: string, file: File) => {
    try {
      const updatedAssistant = await updateAssistantProfileImage(assistantId, file);
      setAssistants(prevAssistants => 
        prevAssistants.map(assistant => 
          assistant.assistant_id === assistantId ? updatedAssistant : assistant
        )
      );
    } catch (error) {
      console.error('Error updating profile image:', error);
    }
  };

  return (
    <SidebarList 
      onAssistantClick={handleAssistantClick} 
      assistants={assistants} 
      onInitialAssistantClick={handleInitialAssistantClick}
      isCollapsed={isCollapsed}
      onToggleCollapse={toggleCollapse}
      onImageUpload={handleImageUpload}
    />
  );
};