import React, { useState, useEffect } from 'react';
import { Bitcoin, Loader2 } from 'lucide-react';

interface CryptoPaymentFormProps {
  amount: number;
  currency: string;
}

interface CryptoCurrency {
  code: string;
  name: string;
}

const CryptoPaymentForm: React.FC<CryptoPaymentFormProps> = ({ amount, currency }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hostedUrl, setHostedUrl] = useState<string | null>(null);
  const [cryptocurrencies, setCryptocurrencies] = useState<CryptoCurrency[]>([]);
  const [selectedCrypto, setSelectedCrypto] = useState<string>('');

  useEffect(() => {
    // For this example, we'll use a static list of cryptocurrencies
    // In a real application, you might want to fetch this from Coinbase Commerce API
    setCryptocurrencies([
      { code: 'BTC', name: 'Bitcoin' },
      { code: 'ETH', name: 'Ethereum' },
      { code: 'USDC', name: 'USD Coin' },
      // Add more as needed
    ]);
    setSelectedCrypto('BTC'); // Default to Bitcoin
  }, []);

  const createCharge = async () => {
    const url = 'https://api.commerce.coinbase.com/charges';
    const requestBody = {
      local_price: {
        amount: amount.toString(),
        currency: currency,
      },
      pricing_type: 'fixed_price',
      name: 'Payment for Services',
      description: `Payment of ${amount} ${currency}`,
      redirect_url: window.location.origin + '/payment-success', // Adjust as needed
      cancel_url: window.location.origin + '/payment-cancelled', // Adjust as needed
      metadata: {
        selected_crypto: selectedCrypto,
      },
    };

    const payload = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CC-Api-Key': import.meta.env.VITE_COINBASE_COMMERCE_API_KEY,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(url, payload);
      if (!response.ok) {
        throw new Error(`HTTP error Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error("Error creating charge:", error);
      throw error;
    }
  };

  const handleCryptoPayment = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const chargeData = await createCharge();
      if (chargeData && chargeData.data && chargeData.data.hosted_url) {
        setHostedUrl(chargeData.data.hosted_url);
        window.location.href = chargeData.data.hosted_url;
      } else {
        throw new Error('Failed to create Coinbase charge');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 max-w-md mx-auto">
      <h3 className="text-2xl font-bold mb-4 text-center text-gray-800">Pay with Cryptocurrency</h3>
      <div className="flex items-center justify-center mb-6">
        <Bitcoin size={48} className="text-yellow-500 mr-2" />
        <p className="text-xl font-semibold text-gray-700">
          {amount} {currency}
        </p>
      </div>
      
      <div className="mb-4">
        <label htmlFor="crypto-select" className="block text-sm font-medium text-gray-700 mb-2">
          Preferred Cryptocurrency
        </label>
        <select
          id="crypto-select"
          value={selectedCrypto}
          onChange={(e) => setSelectedCrypto(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {cryptocurrencies.map((crypto) => (
            <option key={crypto.code} value={crypto.code}>
              {crypto.name}
            </option>
          ))}
        </select>
      </div>

      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <p>{error}</p>
        </div>
      )}
      
      <button
        onClick={handleCryptoPayment}
        disabled={isLoading || !selectedCrypto}
        className={`w-full py-3 px-6 text-white font-semibold rounded-full transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
          isLoading || !selectedCrypto
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-gradient-to-r from-yellow-400 to-yellow-600 hover:from-yellow-500 hover:to-yellow-700 focus:ring-yellow-500'
        }`}
      >
        {isLoading ? (
          <span className="flex items-center justify-center">
            <Loader2 className="animate-spin mr-2" />
            Processing...
          </span>
        ) : (
          'Pay Now'
        )}
      </button>
    </div>
  );
};

export default CryptoPaymentForm;