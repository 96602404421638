import React from 'react';
import { Card, CardContent, CardActions, Button, Typography, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import { AgentTemplate } from '@/types/AgentTypes';

interface TemplateSelectionProps {
  onSelect: (template: AgentTemplate) => void;
}

const templates: AgentTemplate[] = [
  {
    name: 'Service Agent',
    role: 'Service Agent',
    skill: 'Expert in automotive service and maintenance',
    general_knowledge: 'Comprehensive understanding of vehicle diagnostics and repair procedures',
    personality: 'Friendly, patient, and detail-oriented',
    highlight: 'Specializes in explaining complex technical issues in simple terms',
  },
  {
    name: 'Automotive Sales Agent',
    role: 'Sales Agent',
    skill: 'Proficient in automotive sales techniques and product knowledge',
    general_knowledge: 'In-depth understanding of various car models, features, and market trends',
    personality: 'Charismatic, persuasive, and customer-focused',
    highlight: 'Excels at matching customers with their ideal vehicles',
  },
  {
    name: 'BDC Agent',
    role: 'BDC Agent',
    skill: 'Expertise in customer relationship management and lead qualification',
    general_knowledge: 'Broad understanding of dealership operations and customer service best practices',
    personality: 'Professional, organized, and excellent communicator',
    highlight: 'Specializes in efficiently managing and prioritizing customer inquiries',
  },
];

const TemplateSelection: React.FC<TemplateSelectionProps> = ({ onSelect }) => {
  return (
    <Grid container spacing={3}>
      {templates.map((template, index) => (
        <Grid item xs={12} sm={6} md={4} key={template.name}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <Card>
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  {template.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {template.highlight}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => onSelect(template)}>
                  Select Template
                </Button>
              </CardActions>
            </Card>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
};

export default TemplateSelection;