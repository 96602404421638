import React from 'react';
import { Grid, Typography, Box, CircularProgress, Avatar } from '@mui/material';
import { StyledPaper, StyledTextArea, AnimatedButton } from 'src/components/agentprofile/StyledComponents';
import { FormData } from 'src/types/AgentProfileTypes';
import { Person, EmojiObjects, Psychology, Stars, ContactPhone, AddAPhoto } from '@mui/icons-material';
import defaultAvatar from 'src/assets/images/divergent-agents-logo.png';

interface AgentBasicInfoProps {
  formData: FormData;
  handleTextareaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  isUpdating: boolean;
  phoneNumbers: string[];
  profileImage: string | null;
  handleImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IconTextAreaProps {
  icon: React.ReactNode;
  label: string;
  [key: string]: any;
}

const IconTextArea: React.FC<IconTextAreaProps> = ({ icon, label, ...props }) => (
  <Box mb={3}>
    <Box display="flex" alignItems="center" mb={1}>
      {icon}
      <Typography variant="subtitle1" ml={1}>{label}</Typography>
    </Box>
    <StyledTextArea {...props} />
  </Box>
);

const AgentBasicInfo: React.FC<AgentBasicInfoProps> = ({
  formData,
  handleTextareaChange,
  handleSubmit,
  isUpdating,
  phoneNumbers,
  profileImage,
  handleImageUpload,
}) => {
  return (
    <StyledPaper elevation={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" mb={3}>
            <Avatar
              src={profileImage || defaultAvatar}
              alt="Agent Profile"
              sx={{ width: 100, height: 100, mr: 2 }}
            />
            <label htmlFor="profile-image-upload">
              <input
                id="profile-image-upload"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
              />
              <span>
                <AnimatedButton
                  variant="outlined"
                  color="primary"
                  startIcon={<AddAPhoto />}
                >
                  Upload Image
                </AnimatedButton>
              </span>
            </label>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <IconTextArea
            icon={<Person color="primary" />}
            label="Name"
            minRows={2}
            maxRows={4}
            placeholder="Enter agent's name"
            name="name"
            value={formData.name}
            onChange={handleTextareaChange}
          />
        </Grid>
        <Grid item xs={12}>
          <IconTextArea
            icon={<EmojiObjects color="primary" />}
            label="General Knowledge"
            minRows={4}
            maxRows={6}
            placeholder="Enter agent's general knowledge"
            name="generalKnowledge"
            value={formData.generalKnowledge}
            onChange={handleTextareaChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <IconTextArea
            icon={<Stars color="primary" />}
            label="Skill"
            minRows={2}
            maxRows={4}
            placeholder="Enter agent's skills"
            name="skill"
            value={formData.skill}
            onChange={handleTextareaChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <IconTextArea
            icon={<Psychology color="primary" />}
            label="Personality"
            minRows={2}
            maxRows={4}
            placeholder="Describe agent's personality"
            name="personality"
            value={formData.personality}
            onChange={handleTextareaChange}
          />
        </Grid>
        <Grid item xs={12}>
          <IconTextArea
            icon={<Stars color="primary" />}
            label="Highlights"
            minRows={2}
            maxRows={4}
            placeholder="Enter agent's highlights"
            name="highlights"
            value={formData.highlights}
            onChange={handleTextareaChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" gap={2} alignItems="center">
              <AnimatedButton
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isUpdating}
                startIcon={isUpdating ? <CircularProgress size={20} /> : null}
              >
                {isUpdating ? 'Updating...' : 'Update Agent'}
              </AnimatedButton>
              {phoneNumbers.length > 0 && (
                <Box display="flex" alignItems="center">
                  <ContactPhone color="primary" />
                  <Typography variant="body1" ml={1}>
                    Agent Phone: {phoneNumbers[0]}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default AgentBasicInfo;