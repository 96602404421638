import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useTheme } from 'src/components/ThemeContext';
import { motion } from 'framer-motion';
import { FaLock } from 'react-icons/fa';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

interface StripePaymentFormProps {
  amount: number;
  currency: string;
}

const CheckoutForm: React.FC<StripePaymentFormProps> = ({ amount, currency }) => {
  const { isDarkMode } = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      setError("Stripe.js hasn't loaded yet. Please try again.");
      return;
    }

    setProcessing(true);

    const { error: submitError } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-confirmation`,
      },
    });

    if (submitError) {
      console.error('Payment confirmation error:', submitError);
      setError(submitError.message || 'An error occurred during payment confirmation');
    }

    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <PaymentElement
  options={{
    layout: "tabs",
  }}
/>
      {error && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mt-4 p-3 bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-100 rounded-md"
        >
          {error}
        </motion.div>
      )}
      <motion.button
        type="submit"
        disabled={!stripe || processing}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className={`
          mt-6 w-full px-6 py-3 rounded-md text-white font-semibold
          flex items-center justify-center
          ${isDarkMode
            ? 'bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 disabled:from-gray-700 disabled:to-gray-700'
            : 'bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-blue-600 hover:to-indigo-600 disabled:from-gray-300 disabled:to-gray-300'
          }
          transition-all duration-300 ease-in-out
        `}
      >
        <FaLock className="mr-2" />
        {processing ? 'Processing...' : `Pay ${amount} ${currency.toUpperCase()}`}
      </motion.button>
    </form>
  );
};

const StripePaymentForm: React.FC<StripePaymentFormProps> = (props) => {
  const [options, setOptions] = useState<{
    clientSecret: string;
    appearance: { theme: 'stripe' | 'night' };
  }>({
    clientSecret: '',
    appearance: { theme: 'stripe' },
  });
  const [error, setError] = useState<string | null>(null);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const fetchPaymentIntent = async () => {
      try {
        const response = await fetch(`${import.meta.env.VITE_DB_URL}/api/billing/create-payment-intent`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({ amount: props.amount, currency: props.currency }),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        if (data.clientSecret) {
          setOptions(prev => ({
            ...prev,
            clientSecret: data.clientSecret,
            appearance: { theme: isDarkMode ? 'night' : 'stripe' },
          }));
        } else {
          throw new Error('No client secret received from the server');
        }
      } catch (error) {
        console.error('Error creating payment intent:', error);
        setError(error instanceof Error ? error.message : 'An unknown error occurred');
      }
    };
  
    fetchPaymentIntent();
  }, [props.amount, props.currency, isDarkMode]);

  if (error) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className={`p-4 rounded-md ${isDarkMode ? 'bg-red-900 text-red-100' : 'bg-red-100 text-red-700'}`}
      >
        Error: {error}
      </motion.div>
    );
  }

  return (
    <div className="min-h-[300px] flex items-center justify-center">
      {options.clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm {...props} />
        </Elements>
      ) : (
        <div className="space-y-4 w-full max-w-md">
          <div className={`h-10 rounded-md ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} animate-pulse`}></div>
          <div className={`h-10 rounded-md ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} animate-pulse`}></div>
          <div className={`h-10 rounded-md ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} animate-pulse`}></div>
        </div>
      )}
    </div>
  );
};

export default StripePaymentForm;
