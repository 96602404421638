import React, { useEffect, useState } from 'react';
import {
  Container, Grid, TextField, Button, Typography, Paper, Box, CircularProgress, Alert
} from '@mui/material';
import styles from './BillingPage.module.scss';
import BillingSummary from '../BillingSummary';
import PaymentForm from './PaymentForm';
import { fetchCurrentUser, getBillingInfo, getInvoices, updateBillingInfo } from '@/api/api';
import { useTheme } from '../ThemeContext';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';

const DB_URL = import.meta.env.VITE_DB_URL;

interface NonEditableBillingInfo {
  user_id: string;
  account_id: string;
  organization_id: string;
  account_name: string;
}

interface EditableBillingInfo {
  business_name: string;
  state: string;
  country: string;
  zip: string;
  address: string;
  billing_address: string;
}

interface Invoice {
  date_created: string;
  status: string;
  amount: number;
  currency: string;
  usage_time: number;
}

const BillingPage: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [nonEditableBillingInfo, setNonEditableBillingInfo] = useState<NonEditableBillingInfo>({
    user_id: '',
    account_id: '',
    organization_id: '',
    account_name: '',
  });
  const [editableBillingInfo, setEditableBillingInfo] = useState<EditableBillingInfo>({
    business_name: '',
    state: '',
    country: '',
    zip: '',
    address: '',
    billing_address: '',
  });

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    setLoading(true);
    setError(null);
    try {
      const userData = await fetchCurrentUser();
      if (!userData.account_id) {
        throw new Error('No account_id found in user data');
      }
      setNonEditableBillingInfo({
        user_id: userData.user_id,
        account_id: userData.account_id,
        organization_id: userData.organization_id,
        account_name: userData.account_name,
      });
      await Promise.all([
        fetchBillingInfoData(userData.account_id),
        fetchInvoicesData(userData.account_id)
      ]);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  const fetchBillingInfoData = async (accountId: string) => {
    try {
      const data = await getBillingInfo(accountId);
      setEditableBillingInfo({
        business_name: data.business_name,
        state: data.state,
        country: data.country,
        zip: data.zip,
        address: data.address,
        billing_address: data.billing_address,
      });
    } catch (error) {
      throw new Error('Error fetching billing information');
    }
  };

  const fetchInvoicesData = async (accountId: string) => {
    try {
      const data = await getInvoices(accountId);
      setInvoices(data);
    } catch (error) {
      throw new Error('Error fetching invoices');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditableBillingInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleSaveBillingInfo = async () => {
    if (!nonEditableBillingInfo.account_id) {
      setError('Account ID is not available');
      return;
    }
    try {
      const data = await updateBillingInfo(nonEditableBillingInfo.account_id, editableBillingInfo);
      setEditableBillingInfo(data);
      alert('Billing information updated successfully');
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to update billing information');
    }
  };

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Alert severity="error">
        {error}
        <Button color="inherit" size="small" onClick={fetchUserInfo}>
          Retry
        </Button>
      </Alert>
    );
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Container className={styles.container}>
        <Typography variant="h4" gutterBottom>
          Billing & Usage
        </Typography>
        <Box mb={3}>
          <BillingSummary />
        </Box>
        <Paper elevation={3} className={styles.paper}>
          <Typography variant="h5" gutterBottom>
            Account Information
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(nonEditableBillingInfo).map(([key, value]) => (
              <Grid item xs={12} sm={6} key={key}>
                <TextField
                  label={key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')}
                  value={value}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
        <Paper elevation={3} className={styles.paper}>
          <Typography variant="h5" gutterBottom>
            Billing Information
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(editableBillingInfo).map(([key, value]) => (
              <Grid item xs={12} sm={6} key={key}>
                <TextField
                  label={key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')}
                  name={key}
                  value={value}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleSaveBillingInfo}>
                Save Billing Info
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Box mt={3}>
          <Paper elevation={3} className={styles.paper}>
            <Typography variant="h5" gutterBottom>
              Make a Payment
            </Typography>
            <PaymentForm />
          </Paper>
        </Box>
        <Box mt={3}>
          <Paper elevation={3} className={styles.paper}>
            <Typography variant="h5" gutterBottom>
              Invoices
            </Typography>
            {invoices.length > 0 ? (
              <Grid container spacing={2}>
                {invoices.map((invoice, index) => (
                  <Grid item xs={12} key={index}>
                    <Paper elevation={1} className={styles.invoice}>
                      <Typography>Date: {new Date(invoice.date_created).toLocaleDateString()}</Typography>
                      <Typography>Status: {invoice.status}</Typography>
                      <Typography>Amount: {invoice.amount} {invoice.currency}</Typography>
                      <Typography>Usage Time: {invoice.usage_time} hours</Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>No invoices found.</Typography>
            )}
          </Paper>
        </Box>
      </Container>
    </MuiThemeProvider>
  );
};

export default BillingPage;
