import React, { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { decodeJWT, checkAdminStatus, fetchUserProfile } from '@/api/api';
import { useMsal } from "@azure/msal-react";
import { UserPlan, UserProfile } from '@/types/AgentTypes';

export interface User {
  id: string;
  email: string;
  profilePicture?: string;
  isAdmin: boolean;
  organizationId: string | null;
  planDetails?: UserPlan;
  planId?: string;
}


interface AuthContextType {
  isAuthenticated: boolean;
  setAuthenticated: (auth: boolean) => void;
  userId: string | null;
  setUserId: (id: string | null) => void;
  user: User | null;
  setUser: (user: User | null) => void;
  logout: () => Promise<void>;
  login: (userId: string, user: User, rememberMe: boolean) => void;
  loginWithRedirect: () => Promise<void>;
  checkAuthStatus: () => Promise<void>;
  isLoading: boolean;
  isAdmin: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { instance } = useMsal();

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        setAuthenticated(true);
        const decodedToken = decodeJWT(token);
        setUserId(decodedToken.userId);

        // Fetch user profile to get the latest user data, including organizationId and planDetails
        try {
          const userProfile = await fetchUserProfile();
          setUser({ 
            id: userProfile.id, 
            email: userProfile.email,
            profilePicture: userProfile.profilePicture || undefined,
            isAdmin: userProfile.isAdmin,
            organizationId: userProfile.organizationId,
            planDetails: userProfile.plan,
            planId: userProfile.plan?.id
          });

          setIsAdmin(userProfile.isAdmin);
        } catch (error) {
          console.error('Error fetching user profile:', error);
          setUser({
            id: decodedToken.userId,
            email: decodedToken.email,
            profilePicture: decodedToken.profilePicture,
            isAdmin: decodedToken.isAdmin || false,
            organizationId: decodedToken.organizationId || null
          });

          // Check if the user is an admin
          checkAdminStatus().then(setIsAdmin).catch(() => setIsAdmin(false));
        }
      } else {
        setAuthenticated(false);
      }
      setIsLoading(false);
    };

    initializeAuth();
  }, []);

  const login = (userId: string, user: User, rememberMe: boolean) => {
    setUserId(userId);
    setUser(user);
    setAuthenticated(true);
    setIsAdmin(user.isAdmin);
    if (rememberMe) {
      localStorage.setItem('rememberMe', 'true');
    } else {
      localStorage.removeItem('rememberMe');
    }
  };

  const logout = useCallback(async () => {
    localStorage.removeItem('token');
    setAuthenticated(false);
    setUserId(null);
    setUser(null);
    setIsAdmin(false);
    await instance.logoutPopup();
    window.location.href = 'https://beta.divergentagents.com/login'; // Redirect to login page
  }, [instance]);

  const loginWithRedirect = async () => {
    try {
      await instance.loginPopup({
        scopes: ["user.read"],
        prompt: "select_account"
      });
    } catch (error) {
      console.error("Error during Microsoft login:", error);
    }
  };

  const checkAuthStatus = async () => {
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      setAuthenticated(true);
      // You might want to fetch user details here and update the state
    } else {
      setAuthenticated(false);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setAuthenticated, userId, setUserId, user, setUser, isAdmin, logout, login, loginWithRedirect, checkAuthStatus, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
