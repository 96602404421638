import React, { useState } from 'react';
import StripePaymentForm from './StripePaymentForm';
import CryptoPaymentForm from './CryptoPaymentForm';
import { useTheme } from '../ThemeContext';

const PaymentForm: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [paymentMethod, setPaymentMethod] = useState<'stripe' | 'crypto'>('stripe');
  const [amount, setAmount] = useState(100); // Example amount, replace with your actual amount logic
  const [currency, setCurrency] = useState('USD'); // Example currency, replace with your actual currency logic

  const PaymentButton: React.FC<{ method: 'stripe' | 'crypto'; label: string }> = ({ method, label }) => (
    <button
      className={`px-6 py-3 font-semibold rounded-full focus:outline-none transition duration-300 ease-in-out transform ${
        paymentMethod === method
          ? isDarkMode
            ? 'bg-blue-600 text-white scale-105'
            : 'bg-blue-600 text-white scale-105'
          : isDarkMode
          ? 'bg-gray-700 text-gray-300 hover:bg-blue-500 hover:text-white'
          : 'bg-gray-300 text-gray-700 hover:bg-blue-500 hover:text-white'
      }`}
      onClick={() => setPaymentMethod(method)}
      aria-pressed={paymentMethod === method}
    >
      {label}
    </button>
  );

  return (
    <div className={`max-w-lg mx-auto mt-10 p-8 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-xl rounded-lg transition duration-500 ease-in-out transform hover:shadow-2xl`}>
      <h2 className={`text-3xl font-bold mb-6 text-center ${isDarkMode ? 'text-gray-100' : 'text-gray-800'}`}>Select Payment Method</h2>
      <div className="flex justify-center mb-8 space-x-4">
        <PaymentButton method="stripe" label="Credit/Debit Card" />
        <PaymentButton method="crypto" label="Cryptocurrency" />
      </div>
      <div className="transition duration-300 ease-in-out">
        {paymentMethod === 'stripe' ? (
          <StripePaymentForm amount={amount} currency={currency} />
        ) : (
          <CryptoPaymentForm amount={amount} currency={currency} />
        )}
      </div>
    </div>
  );
};

export default PaymentForm;