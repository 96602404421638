import React, { useState } from 'react';
import { changeUserPassword } from '@/api/api';
import { useTheme } from '../ThemeContext';

const ChangePassword: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setError('New passwords do not match.');
      return;
    }

    try {
      const response = await changeUserPassword({ oldPassword, newPassword });
      if (response.success) {
        setSuccess('Password changed successfully.');
        setError('');
      } else {
        setError(response.message || 'Failed to change password.');
        setSuccess('');
      }
    } catch (err) {
      setError('An error occurred while changing the password.');
      setSuccess('');
    }
  };

  const themeClasses = {
    container: isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900',
    input: isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-gray-50 border-gray-300 text-gray-900',
    button: isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-700',
    label: isDarkMode ? 'text-gray-200' : 'text-gray-900',
    errorText: isDarkMode ? 'text-red-400' : 'text-red-500',
    successText: isDarkMode ? 'text-green-400' : 'text-green-500',
  };

  return (
    <div className={`max-w-md mx-auto mt-10 p-6 ${themeClasses.container} rounded-lg shadow-md`}>
      <h1 className="text-2xl font-bold mb-6 text-center">Change Password</h1>
      {error && <p className={themeClasses.errorText}>{error}</p>}
      {success && <p className={themeClasses.successText}>{success}</p>}
      <div className="mb-6">
        <label className={`block mb-2 text-sm font-medium ${themeClasses.label}`}>Old Password</label>
        <input
          type="password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          className={`${themeClasses.input} text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
          placeholder="Enter old password"
        />
      </div>
      <div className="mb-6">
        <label className={`block mb-2 text-sm font-medium ${themeClasses.label}`}>New Password</label>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className={`${themeClasses.input} text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
          placeholder="Enter new password"
        />
      </div>
      <div className="mb-6">
        <label className={`block mb-2 text-sm font-medium ${themeClasses.label}`}>Confirm New Password</label>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className={`${themeClasses.input} text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
          placeholder="Confirm new password"
        />
      </div>
      <button
        onClick={handlePasswordChange}
        className={`w-full ${themeClasses.button} text-white py-2 px-4 rounded-lg transition-colors`}
      >
        Change Password
      </button>
    </div>
  );
};

export default ChangePassword;