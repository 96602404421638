import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, Snackbar, FormGroup, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createXTimeTool, assignToolToAssistant, fetchUserAssistants } from '@/api/api';

interface IntegrationConfig {
  dealerCode: string;
  defaultVin: string;
  defaultOpcode: string;
}

interface Assistant {
  assistant_id: string;
  name: string;
  phone_numbers: string | string[];
}

const XtimeComponent: React.FC = () => {
  const [config, setConfig] = useState<IntegrationConfig>({
    dealerCode: '',
    defaultVin: '',
    defaultOpcode: '',
  });
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [selectedAssistants, setSelectedAssistants] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [toolId, setToolId] = useState<string | null>(null);

  useEffect(() => {
    const loadAssistants = async () => {
      try {
        const fetchedAssistants = await fetchUserAssistants();
        setAssistants(fetchedAssistants);
      } catch (error) {
        console.error('Error fetching assistants:', error);
        setSnackbarMessage('Failed to load assistants. You can still create the integration.');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    loadAssistants();
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setConfig(prevConfig => ({ ...prevConfig, [name]: value }));
  };

  const handleAssistantToggle = (assistantId: string) => {
    setSelectedAssistants(prev => 
      prev.includes(assistantId)
        ? prev.filter(id => id !== assistantId)
        : [...prev, assistantId]
    );
  };

  const handleCreateIntegration = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await createXTimeTool(config.dealerCode, config.defaultVin, config.defaultOpcode);
      setToolId(response.toolId);
      setSnackbarMessage('xTime integration created successfully! You can now assign it to assistants.');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error creating xTime integration:', error);
      setSnackbarMessage('Error creating xTime integration. Please try again.');
      setSnackbarOpen(true);
    }
  };

  const handleAssignIntegration = async () => {
    if (!toolId) {
      setSnackbarMessage('Please create the integration first.');
      setSnackbarOpen(true);
      return;
    }

    if (selectedAssistants.length === 0) {
      setSnackbarMessage('Please select at least one assistant.');
      setSnackbarOpen(true);
      return;
    }

    try {
      for (const assistantId of selectedAssistants) {
        await assignToolToAssistant(assistantId, toolId, config);
      }

      setSnackbarMessage('xTime integration assigned successfully!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error assigning xTime integration:', error);
      setSnackbarMessage('Error assigning xTime integration. Please try again.');
      setSnackbarOpen(true);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        xTime Integration Configuration
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Available Time Slots Configuration</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={handleCreateIntegration}>
            <TextField
              fullWidth
              margin="normal"
              label="Dealer Code"
              name="dealerCode"
              value={config.dealerCode}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Default VIN (Optional)"
              name="defaultVin"
              value={config.defaultVin}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Default Opcode (Optional)"
              name="defaultOpcode"
              value={config.defaultOpcode}
              onChange={handleInputChange}
            />
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              Create Integration
            </Button>
          </form>
        </AccordionDetails>
      </Accordion>

      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
        Assign Integration to Assistants:
      </Typography>
      {assistants.length > 0 ? (
        <FormGroup>
          {assistants.map((assistant) => (
            <FormControlLabel
              key={assistant.assistant_id}
              control={
                <Checkbox
                  checked={selectedAssistants.includes(assistant.assistant_id)}
                  onChange={() => handleAssistantToggle(assistant.assistant_id)}
                />
              }
              label={`${assistant.name} (${Array.isArray(assistant.phone_numbers) ? assistant.phone_numbers.join(', ') : assistant.phone_numbers})`}
            />
          ))}
        </FormGroup>
      ) : (
        <Typography color="text.secondary">
          No assistants available. You can still create the integration and assign it later.
        </Typography>
      )}
      <Button 
        onClick={handleAssignIntegration} 
        variant="contained" 
        color="secondary" 
        sx={{ mt: 2 }}
        disabled={!toolId || assistants.length === 0}
      >
        Assign Integration to Selected Assistants
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default XtimeComponent;