import React, { useState, useEffect, useRef } from 'react';
import {
  uploadProfilePicture,
  setup2FA,
  verify2FA,
  getTwoFactorStatus,
  disable2FA,
  connectGoogleAccount,
  checkGoogleConnectionStatus,
  fetchUserProfile,
  connectMicrosoftAccount,
  checkMicrosoftConnectionStatus
} from 'src/api/api';
import ChangePassword from './ChangePassword';
import { useProfile } from 'src/components/account/ProfileContext';
import { FaUpload, FaLock, FaSync } from 'react-icons/fa';
import { Switch } from '@headlessui/react';
import { useTheme } from '../ThemeContext';
import { useAuth } from '../AuthContext';
import { GoogleLogin, CredentialResponse, GoogleOAuthProvider } from '@react-oauth/google';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_AZURE_CLIENT_ID,
    authority: "https://login.microsoftonline.com/d39e7a3f-9c3c-44a0-bd6e-40d7c2630a11",
    redirectUri: import.meta.env.VITE_AZURE_REDIRECT_URI
  }
};

const pca = new PublicClientApplication(msalConfig);

const Settings: React.FC = () => {
  const { isDarkMode } = useTheme();
  const { setProfileImage } = useProfile();
  const [image, setImage] = useState<File | null>(null);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [setupMode, setSetupMode] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [currentProfilePicture, setCurrentProfilePicture] = useState<string | null>(null);
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);
  const [isMicrosoftConnected, setIsMicrosoftConnected] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { user, setUser } = useAuth();

  useEffect(() => {
    fetchTwoFactorStatus();
    fetchCurrentProfilePicture();
    checkGoogleAccountConnection();
    checkMicrosoftAccountConnection();
  }, []);

  const fetchTwoFactorStatus = async () => {
    try {
      const status = await getTwoFactorStatus();
      setTwoFactorEnabled(status.enabled);
    } catch (error) {
      console.error('Error fetching 2FA status:', error);
      setError('Failed to fetch 2FA status');
    }
  };

  const fetchCurrentProfilePicture = async () => {
    try {
      const userProfile = await fetchUserProfile();
      setCurrentProfilePicture(userProfile.profilePicture);
    } catch (error) {
      console.error('Error fetching current profile picture:', error);
      setError('Failed to fetch current profile picture');
      setCurrentProfilePicture(null);
    }
  };

  const checkGoogleAccountConnection = async () => {
    try {
      const isConnected = await checkGoogleConnectionStatus();
      setIsGoogleConnected(isConnected);
    } catch (error) {
      console.error('Error checking Google connection status:', error);
      setError('Failed to check Google connection status');
    }
  };

  const checkMicrosoftAccountConnection = async () => {
    try {
      const isConnected = await checkMicrosoftConnectionStatus();
      setIsMicrosoftConnected(isConnected);
    } catch (error) {
      console.error('Error checking Microsoft connection status:', error);
      setError('Failed to check Microsoft connection status');
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
      setImagePreview(null);
    }
  };

  const handleImageUpload = async () => {
    if (image) {
      setIsLoading(true);
      setError(null);
      setSuccess(null);
      try {
        const formData = new FormData();
        formData.append('profilePicture', image);
        const response = await uploadProfilePicture(formData);
        setProfileImage(response.filePath);
        setCurrentProfilePicture(response.filePath);
        setSuccess('Profile picture uploaded successfully');
        if (typeof window !== 'undefined') {
          window.dispatchEvent(new Event('profilePictureUpdated'));
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        setError('Failed to upload profile picture. Please try again.');
      } finally {
        setIsLoading(false);
      }
    } else {
      setError('Please select an image to upload');
    }
  };

  const refreshProfilePicture = async () => {
    try {
      await fetchCurrentProfilePicture();
      setSuccess('Profile picture refreshed');
    } catch (error) {
      console.error('Error refreshing profile picture:', error);
      setError('Failed to refresh profile picture');
    }
  };

  const handleGoogleSuccess = async (credentialResponse: CredentialResponse) => {
    try {
      if (credentialResponse.credential) {
        const result = await connectGoogleAccount(credentialResponse.credential);
        setUser(result.user);
        setIsGoogleConnected(true);
        setSuccess('Google account connected successfully');
      } else {
        setError('Failed to connect Google account. No credential received.');
      }
    } catch (error) {
      console.error('Error connecting Google account:', error);
      setError('Failed to connect Google account. Please try again.');
    }
  };

  const handleGoogleFailure = () => {
    setError('Failed to connect Google account. Please try again.');
  };

  const handleTwoFactorToggle = async () => {
    setError(null);
    setSuccess(null);
    setIsLoading(true);
    if (!twoFactorEnabled) {
      try {
        const response = await setup2FA();
        setQrCode(response.qrCode);
        setSetupMode(true);
      } catch (error) {
        console.error('Error setting up 2FA:', error);
        setError('Failed to set up 2FA');
      }
    } else {
      await handleDisable2FA();
    }
    setIsLoading(false);
  };

  const handleVerifyCode = async () => {
    setError(null);
    setSuccess(null);
    setIsLoading(true);
    try {
      if (!user?.email) {
        throw new Error('User email not found');
      }
      const result = await verify2FA(user.email, verificationCode);
      if (result.success) {
        setTwoFactorEnabled(true);
        setSetupMode(false);
        setQrCode(null);
        setSuccess('2FA enabled successfully');
      } else {
        setError('Invalid verification code');
      }
    } catch (error) {
      console.error('Error verifying 2FA code:', error);
      setError('Failed to verify 2FA code');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisable2FA = async () => {
    setError(null);
    setSuccess(null);
    setIsLoading(true);
    try {
      await disable2FA();
      setTwoFactorEnabled(false);
      setQrCode(null);
      setSetupMode(false);
      setSuccess('2FA disabled successfully');
    } catch (error) {
      console.error('Error disabling 2FA:', error);
      setError('Failed to disable 2FA');
    } finally {
      setIsLoading(false);
    }
  };

  const themeClasses = {
    container: isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800',
    card: isDarkMode ? 'bg-gray-800' : 'bg-white',
    button: {
      primary: isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600',
      secondary: isDarkMode ? 'bg-red-600 hover:bg-red-700' : 'bg-red-500 hover:bg-red-600',
      success: isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600',
    },
    input: isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-700',
    switch: {
      enabled: isDarkMode ? 'bg-blue-600' : 'bg-blue-600',
      disabled: isDarkMode ? 'bg-gray-600' : 'bg-gray-200',
    },
  };

  const ProfilePictureSection = () => (
    <div className={`${themeClasses.card} p-6 rounded-lg shadow-md`}>
      <h2 className="text-xl font-semibold mb-4">Change Profile Picture</h2>
      {currentProfilePicture && (
        <div className="mb-4">
          <img src={currentProfilePicture} alt="Current Profile" className="max-w-full h-auto rounded-full" />
        </div>
      )}
      <input
        ref={fileInputRef}
        accept="image/*"
        type="file"
        onChange={handleImageChange}
        className={`block w-full text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-500'} file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold ${isDarkMode ? 'file:bg-gray-700 file:text-white' : 'file:bg-blue-50 file:text-blue-700'} hover:file:bg-blue-100`}
      />
      {imagePreview && (
        <div className="mt-4">
          <img src={imagePreview} alt="Preview" className="max-w-full h-auto rounded-full" />
        </div>
      )}
      <div className="flex justify-between mt-4">
        <button
          onClick={handleImageUpload}
          disabled={isLoading || !image}
          className={`${themeClasses.button.primary} ${!image && 'opacity-50 cursor-not-allowed'} text-white py-2 px-4 rounded-lg transition-colors flex items-center justify-center`}
        >
          {isLoading ? (
            <span className="animate-spin mr-2">◠</span>
          ) : (
            <FaUpload className="mr-2" />
          )}
          Upload Image
        </button>
        <button
          onClick={refreshProfilePicture}
          className={`${themeClasses.button.secondary} text-white py-2 px-4 rounded-lg transition-colors flex items-center justify-center`}
        >
          <FaSync className="mr-2" />
          Refresh
        </button>
      </div>
    </div>
  );

  const GoogleConnectSection = () => (
    <div className={`${themeClasses.card} p-6 rounded-lg shadow-md mt-6`}>
      <h2 className="text-xl font-semibold mb-4">Connect Google Account</h2>
      {isGoogleConnected ? (
        <div className="text-green-600 font-semibold">Connected</div>
      ) : (
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleFailure}
          useOneTap
          theme="filled_blue"
          size="large"
          text="continue_with"
          shape="rectangular"
        />
      )}
    </div>
  );

  const MicrosoftConnectSection = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const handleMicrosoftLogin = async () => {
      try {
        const response = await instance.loginPopup();
        if (response.account) {
          const result = await connectMicrosoftAccount(response.accessToken);
          setIsMicrosoftConnected(true);
          setSuccess('Microsoft account connected successfully');
        }
      } catch (error) {
        console.error('Error connecting Microsoft account:', error);
        setError('Failed to connect Microsoft account. Please try again.');
      }
    };

    return (
      <div className={`${themeClasses.card} p-6 rounded-lg shadow-md mt-6`}>
        <h2 className="text-xl font-semibold mb-4">Connect Microsoft Account</h2>
        {isMicrosoftConnected ? (
          <div className="text-green-600 font-semibold">Connected</div>
        ) : (
          <button
            onClick={handleMicrosoftLogin}
            className={`${themeClasses.button.primary} text-white py-2 px-4 rounded-lg transition-colors flex items-center justify-center`}
          >
            Connect Microsoft Account
          </button>
        )}
      </div>
    );
  };

  const TwoFactorAuthSection = () => (
    <div className={`${themeClasses.card} p-6 rounded-lg shadow-md mt-6`}>
      <h2 className="text-xl font-semibold mb-4">Two-Factor Authentication</h2>
      <div className="flex items-center justify-between mb-4">
        <span>Enable Two-Factor Authentication</span>
        <Switch
          checked={twoFactorEnabled}
          onChange={handleTwoFactorToggle}
          className={`${twoFactorEnabled ? themeClasses.switch.enabled : themeClasses.switch.disabled} relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
        >
          <span
            className={`${twoFactorEnabled ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
      {setupMode && (
        <div className="mt-4">
          <p className="mb-2">Scan this QR code with your authenticator app:</p>
          {qrCode && (
            <div className="flex justify-center mb-4">
              <img src={qrCode} alt="2FA QR Code" className="w-48 h-48" />
            </div>
          )}
          <input
            type="text"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder="Enter verification code"
            className={`w-full px-3 py-2 mb-2 border ${isDarkMode ? 'border-gray-700' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${themeClasses.input}`}
          />
          <button
            onClick={handleVerifyCode}
            disabled={isLoading}
            className={`w-full ${themeClasses.button.success} text-white py-2 px-4 rounded-lg transition-colors flex items-center justify-center`}
          >
            {isLoading ? (
              <span className="animate-spin mr-2">◠</span>
            ) : (
              <FaLock className="mr-2" />
            )}
            Verify and Enable 2FA
          </button>
        </div>
      )}
    </div>
  );

  const SettingsContent = () => (
    <div className={`${themeClasses.container} max-w-4xl mx-auto mt-20 p-6`}>
      <h1 className="text-3xl font-bold mb-8 text-center">Settings</h1>
      {error && (
        <div className={`${isDarkMode ? 'bg-red-900 border-red-700' : 'bg-red-100 border-red-500'} border-l-4 text-red-700 p-4 mb-6`} role="alert">
          <p>{error}</p>
        </div>
      )}
      {success && (
        <div className={`${isDarkMode ? 'bg-green-900 border-green-700' : 'bg-green-100 border-green-500'} border-l-4 text-green-700 p-4 mb-6`} role="alert">
          <p>{success}</p>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <ProfilePictureSection />
          <GoogleConnectSection />
          <MicrosoftConnectSection />
        </div>
        <div>
          <ChangePassword />
          <TwoFactorAuthSection />
        </div>
      </div>
    </div>
  );

  return (
    <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
      <MsalProvider instance={pca}>
        <SettingsContent />
      </MsalProvider>
    </GoogleOAuthProvider>
  );
};

export default Settings;