import React, { useState, useEffect } from 'react';
import { TextField, Button, List, ListItem, ListItemText, Paper, Typography, Box, Grid } from '@mui/material';
import { ClientData, AgentTemplate } from '@/types/AgentTypes';
import { searchClients, createClient } from '@/api/api';
import TemplateSelection from './TemplateSelection';

interface ClientSelectionProps {
  onSelect: (client: ClientData, template?: AgentTemplate) => void;
}

const ClientSelection: React.FC<ClientSelectionProps> = ({ onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<ClientData[]>([]);
  const [newClient, setNewClient] = useState<ClientData>({
    id: null,
    email: '',
    dealership_name: '',
    dealership_website_url: '',
    address: '',
    dealership_id: null,
    first_name: '',
    last_name: '',
    country: '',
    state: '',
    zip: '',
    organization_id: null, 
  });
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<AgentTemplate | undefined>(undefined);

  useEffect(() => {
    if (searchTerm.length > 2) {
      const delayDebounceFn = setTimeout(() => {
        handleSearch();
      }, 300);
      return () => clearTimeout(delayDebounceFn);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const handleSearch = async () => {
    try {
      const results = await searchClients(searchTerm);
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching clients:', error);
    }
  };

  const handleClientSelect = (client: ClientData) => {
    onSelect(client, selectedTemplate);
  };

  const handleNewClientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewClient(prev => ({ ...prev, [name]: value }));
  };

  const handleCreateClient = async () => {
    try {
      const createdClient = await createClient(newClient);
      onSelect(createdClient, selectedTemplate);
    } catch (error) {
      console.error('Error creating client:', error);
    }
  };

  const handleTemplateSelect = (template: AgentTemplate) => {
    setSelectedTemplate(template);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Select or Create a Client
      </Typography>
      <TextField
        fullWidth
        label="Search Clients"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      {searchResults.length > 0 && (
        <Paper elevation={3} sx={{ mb: 2, maxHeight: 200, overflow: 'auto' }}>
          <List>
            {searchResults.map((client) => (
              <ListItem 
                button 
                key={client.id} 
                onClick={() => handleClientSelect(client)}
              >
                <ListItemText 
                  primary={client.dealership_name} 
                  secondary={client.email} 
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      <Button
        variant="outlined"
        onClick={() => setIsCreatingNew(!isCreatingNew)}
        sx={{ mb: 2 }}
      >
        {isCreatingNew ? 'Cancel' : 'Create New Client'}
      </Button>
      {isCreatingNew && (
        <Box component={Paper} elevation={3} sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            New Client Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="first_name"
                value={newClient.first_name}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="last_name"
                value={newClient.last_name}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={newClient.email}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Dealership Name"
                name="dealership_name"
                value={newClient.dealership_name}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Dealership Website URL"
                name="dealership_website_url"
                value={newClient.dealership_website_url}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                value={newClient.address}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Country"
                name="country"
                value={newClient.country}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="State"
                name="state"
                value={newClient.state}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="ZIP"
                name="zip"
                value={newClient.zip}
                onChange={handleNewClientChange}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            onClick={handleCreateClient}
            disabled={!newClient.email || !newClient.dealership_name}
            sx={{ mt: 2 }}
          >
            Create Client
          </Button>
        </Box>
      )}
      <TemplateSelection onSelect={handleTemplateSelect} />
    </Box>
  );
};

export default ClientSelection;