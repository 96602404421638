import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Paper,
  Grid,
  Chip,
  Tooltip,
  CircularProgress,
  Box,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Phone as PhoneIcon,
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { AgentData, PhoneNumber, BuyPhoneNumberRequest } from '@/types/AgentTypes';
import {
  fetchUserPhoneNumbers,
  assignPhoneNumber,
  unassignPhoneNumber,
  buyPhoneNumber,
} from '@/api/api';

interface PhoneNumberAssignmentBasicProps {
  agentData: AgentData;
  onChange: (newData: Partial<AgentData>) => void;
  onNext: () => void;
}

const PhoneNumberAssignmentBasic: React.FC<PhoneNumberAssignmentBasicProps> = ({
  agentData,
  onChange,
  onNext,
}) => {
  console.log('Rendering PhoneNumberAssignmentBasic');
  console.log('Agent Data:', agentData);
  console.log('Assistant ID:', agentData.assistant_id);
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [areaCode, setAreaCode] = useState('');
  const [phoneNumberName, setPhoneNumberName] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchPhoneNumbers();
  }, []);

  const fetchPhoneNumbers = async () => {
    try {
      const fetchedPhoneNumbers = await fetchUserPhoneNumbers();
      setPhoneNumbers(fetchedPhoneNumbers);
    } catch (error) {
      console.error('Error fetching phone numbers:', error);
      showSnackbar('Failed to fetch phone numbers', 'error');
    }
  };

  const handleBuyPhoneNumber = async () => {
    if (areaCode.trim() !== '' && phoneNumberName.trim() !== '' && agentData.assistant_id) {
      setIsLoading(true);
      try {
        const request: BuyPhoneNumberRequest = {
          areaCode,
          name: phoneNumberName,
          assistantId: agentData.assistant_id
        };
        console.log('Buying phone number with request:', request);
        const boughtPhoneNumber = await buyPhoneNumber(request);
        console.log('Bought phone number:', boughtPhoneNumber);

        await fetchPhoneNumbers();

        const updatedAgentData = {
          ...agentData,
          phone_numbers: [
            ...(agentData.phone_numbers || []),
            boughtPhoneNumber.number,
          ],
        };
        onChange(updatedAgentData);

        setAreaCode('');
        setPhoneNumberName('');
        setIsDialogOpen(false);
        showSnackbar('Phone number bought and assigned successfully', 'success');
      } catch (error) {
        console.error('Error buying and assigning phone number:', error);
        if (error instanceof Error) {
          showSnackbar(`Failed to buy and assign phone number: ${error.message}`, 'error');
        } else {
          showSnackbar('Failed to buy and assign phone number. Please try again.', 'error');
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log('Invalid input or missing assistant ID');
      showSnackbar('Please fill in all fields and ensure the agent has an ID', 'error');
    }
  };

  const handleAssignPhoneNumber = async (phoneNumberId: number) => {
    try {
      if (agentData.assistant_id) {
        await assignPhoneNumber(phoneNumberId, agentData.assistant_id);
        await fetchPhoneNumbers();

        const assignedPhoneNumber = phoneNumbers.find(
          (pn) => pn.id === phoneNumberId
        );
        if (assignedPhoneNumber) {
          const updatedAgentData = {
            ...agentData,
            phone_numbers: [
              ...(agentData.phone_numbers || []),
              assignedPhoneNumber.number,
            ],
          };
          onChange(updatedAgentData);
        }

        showSnackbar('Phone number assigned successfully', 'success');
      } else {
        throw new Error('Assistant ID is missing. Cannot assign phone number.');
      }
    } catch (error) {
      console.error('Error assigning phone number:', error);
      showSnackbar('Failed to assign phone number. Please try again.', 'error');
    }
  };

  const handleUnassignPhoneNumber = async (phoneNumberId: number) => {
    try {
      await unassignPhoneNumber(phoneNumberId);
      await fetchPhoneNumbers();

      const unassignedPhoneNumber = phoneNumbers.find(
        (pn) => pn.id === phoneNumberId
      );
      if (unassignedPhoneNumber) {
        const updatedAgentData = {
          ...agentData,
          phone_numbers: (agentData.phone_numbers || []).filter(
            (num) => num !== unassignedPhoneNumber.number
          ),
        };
        onChange(updatedAgentData);
      }

      showSnackbar('Phone number unassigned successfully', 'success');
    } catch (error) {
      console.error('Error unassigning phone number:', error);
      showSnackbar('Failed to unassign phone number. Please try again.', 'error');
    }
  };

  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const assignedPhoneNumbers = phoneNumbers.filter(
    (pn) => pn.assistantId === agentData.assistant_id
  );
  const availablePhoneNumbers = phoneNumbers.filter(
    (pn) => pn.assistantId === null || pn.assistantId === ''
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Paper
        elevation={3}
        sx={{ p: 4, borderRadius: 2, bgcolor: 'background.default' }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{ display: 'flex', alignItems: 'center', mb: 3 }}
        >
          <PhoneIcon sx={{ mr: 1, color: 'primary.main' }} />
          Phone Number Assignment
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Available Phone Numbers
              </Typography>
              <List>
                <AnimatePresence>
                  {availablePhoneNumbers.map((number) => (
                    <motion.div
                      key={number.id}
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                    >
                      <ListItem>
                        <ListItemText primary={number.number} secondary={number.name} />
                        <ListItemSecondaryAction>
                          <Tooltip title="Assign to this agent">
                            <IconButton
                              edge="end"
                              onClick={() => handleAssignPhoneNumber(number.id)}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </List>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setIsDialogOpen(true)}
                sx={{ mt: 2, borderRadius: 20 }}
              >
                Buy New Phone Number
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Assigned Phone Numbers
              </Typography>
              <List>
                <AnimatePresence>
                  {assignedPhoneNumbers.map((number) => (
                    <motion.div
                      key={number.id}
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                    >
                      <ListItem>
                        <ListItemText
                          primary={number.number}
                          secondary={
                            <>
                              {number.name}
                              <Chip
                                label={`Assigned to ${agentData.name}`}
                                size="small"
                                color="primary"
                                sx={{ ml: 1 }}
                              />
                            </>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Tooltip title="Unassign from this agent">
                            <IconButton
                              edge="end"
                              onClick={() =>
                                handleUnassignPhoneNumber(number.id)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </List>
            </Paper>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={onNext}>
            Next
          </Button>
        </Box>
      </Paper>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Buy New Phone Number</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Area Code"
            type="text"
            fullWidth
            variant="outlined"
            value={areaCode}
            onChange={(e) => setAreaCode(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="Phone Number Name"
            type="text"
            fullWidth
            variant="outlined"
            value={phoneNumberName}
            onChange={(e) => setPhoneNumberName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleBuyPhoneNumber} 
            variant="contained" 
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Buy'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </motion.div>
  );
};

export default PhoneNumberAssignmentBasic;