import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import loadingAnimation from '@/assets/loading-animation.json';

interface AgentBuildingAnimationProps {
  agentName: string;
  voiceSampleUrl: string;
}

const AnimationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
}));

const AgentBuildingAnimation: React.FC<AgentBuildingAnimationProps> = ({ agentName, voiceSampleUrl }) => {
  const [audioPlayer, setAudioPlayer] = useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    return () => {
      if (audioPlayer) {
        audioPlayer.pause();
      }
    };
  }, [audioPlayer]);

  const handlePlayVoiceSample = () => {
    if (audioPlayer) {
      audioPlayer.pause();
    }
    const newAudioPlayer = new Audio(voiceSampleUrl);
    newAudioPlayer.play();
    setAudioPlayer(newAudioPlayer);
  };

  return (
    <AnimationContainer>
      <Lottie 
        animationData={loadingAnimation}
        style={{ height: 400, width: 400 }}
      />
      <Typography variant="h4" gutterBottom>
        {agentName} is being created...
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handlePlayVoiceSample}
        sx={{ mt: 2 }}
      >
        Play Voice Sample
      </Button>
    </AnimationContainer>
  );
};

export default AgentBuildingAnimation;