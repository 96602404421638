import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { createPhoneNumber, fetchUserAssistants } from '@/api/api';
import { FaPhone, FaUser, FaRobot } from 'react-icons/fa';
import { useTheme } from './ThemeContext';

interface Assistant {
  assistant_id: string;
  name: string;
  phone_numbers: string[];
}

interface Props {
  onNumberCreated: () => void;
}

const PhoneNumberCreation: React.FC<Props> = ({ onNumberCreated }) => {
  const { isDarkMode } = useTheme();
  const [name, setName] = useState('');
  const [selectedAssistant, setSelectedAssistant] = useState('');
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    fetchAssistants();
  }, []);

  const fetchAssistants = async () => {
    try {
      const userAssistants = await fetchUserAssistants();
      const transformedAssistants = userAssistants.map(assistant => ({
        ...assistant,
        phone_numbers: Array.isArray(assistant.phone_numbers) ? assistant.phone_numbers : [assistant.phone_numbers]
      }));
      setAssistants(transformedAssistants);
    } catch (err) {
      console.error('Error fetching assistants:', err);
      setError('Failed to load assistants. Please try again.');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await createPhoneNumber(name, selectedAssistant);
      setSuccess('Phone number created successfully!');
      onNumberCreated();
      setName('');
      setSelectedAssistant('');
      fetchAssistants(); // Refresh the list of assistants
    } catch (err) {
      console.error('Error creating phone number:', err);
      setError('Failed to create phone number. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const theme = {
    backgroundColor: isDarkMode ? '#1a1a1a' : '#ffffff',
    textColor: isDarkMode ? '#e0e0e0' : '#333333',
    inputBg: isDarkMode ? '#2a2a2a' : '#ffffff',
    inputBorder: isDarkMode ? '#3a3a3a' : '#e0e0e0',
    buttonBg: isDarkMode ? '#2980b9' : '#3498db',
    buttonHoverBg: isDarkMode ? '#3498db' : '#2980b9',
    errorColor: isDarkMode ? '#e74c3c' : '#c0392b',
    successColor: isDarkMode ? '#2ecc71' : '#27ae60',
    noPhoneColor: isDarkMode ? '#95a5a6' : '#7f8c8d',
  };

  return (
    <ThemeProvider theme={theme}>
      <Card>
        <h2><FaPhone /> Create New Phone Number</h2>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="name">
              <FaUser /> Name
            </Label>
            <Input
              id="name"
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setName(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="assistant">
              <FaRobot /> Select Assistant
            </Label>
            <Select
              id="assistant"
              value={selectedAssistant}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setSelectedAssistant(e.target.value)}
              required
            >
              <option value="">Select an assistant</option>
              {assistants.map((assistant) => (
                <option key={assistant.assistant_id} value={assistant.assistant_id}>
                  {assistant.name}
                </option>
              ))}
            </Select>
          </FormGroup>
          <Button type="submit" disabled={loading}>
            {loading ? <Spinner /> : 'Create Phone Number'}
          </Button>
        </Form>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && <SuccessMessage>{success}</SuccessMessage>}

        <h3>Current Assistants and Phone Numbers</h3>
        <AssistantList>
          {assistants.map((assistant) => (
            <AssistantItem key={assistant.assistant_id}>
              <strong>{assistant.name}</strong>
              {assistant.phone_numbers.length > 0 ? (
                <PhoneList>
                  {assistant.phone_numbers.map((phone, index) => (
                    <PhoneItem key={index}>{phone}</PhoneItem>
                  ))}
                </PhoneList>
              ) : (
                <NoPhone>No phone number assigned</NoPhone>
              )}
            </AssistantItem>
          ))}
        </AssistantList>
      </Card>
    </ThemeProvider>
  );
};

// Styled components
const Card = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.textColor};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
  max-width: 800px;
  margin: 0 auto;

  h2, h3 {
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  h3 {
    font-size: 1.25rem;
    margin-top: 2rem;
  }
`;

const Form = styled.form`
  display: grid;
  gap: 1.5rem;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Input = styled.input`
  background-color: ${props => props.theme.inputBg};
  border-color: ${props => props.theme.inputBorder};
  color: ${props => props.theme.textColor};
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.inputBorder};
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.buttonBg};
  }
`;

const Select = styled.select`
  background-color: ${props => props.theme.inputBg};
  border-color: ${props => props.theme.inputBorder};
  color: ${props => props.theme.textColor};
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.inputBorder};
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.buttonBg};
  }
`;

const Button = styled.button`
  background-color: ${props => props.theme.buttonBg};
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover:not(:disabled) {
    background-color: ${props => props.theme.buttonHoverBg};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const Spinner = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid ${props => props.theme.buttonBg};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ErrorMessage = styled.p`
  color: ${props => props.theme.errorColor};
  margin-top: 1rem;
`;

const SuccessMessage = styled.p`
  color: ${props => props.theme.successColor};
  margin-top: 1rem;
`;

const AssistantList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const AssistantItem = styled.li`
  margin-bottom: 1.5rem;
`;

const PhoneList = styled.ul`
  list-style-type: none;
  padding-left: 1rem;
`;

const PhoneItem = styled.li`
  margin-top: 0.5rem;
`;

const NoPhone = styled.p`
  margin-top: 0.5rem;
  font-style: italic;
  color: ${props => props.theme.noPhoneColor};
`;

export default PhoneNumberCreation;
