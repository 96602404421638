import React, { useState, useEffect } from 'react';
import { Typography, List, ListItem, ListItemText, Paper, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getRecentActivity } from 'src/api/api';
import { Activity } from 'src/types/AgentTypes';


interface ActivityLog {
  id: number;
  user_id: number;
  activity_type: string;
  details: string;
  timestamp: string;
}

const ActivityPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(3),
}));

const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 200,
});

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  textAlign: 'center',
  marginTop: theme.spacing(2),
}));

interface RecentActivityFeedProps {
  assistantId: string;
}

const RecentActivityFeed: React.FC<RecentActivityFeedProps> = ({ assistantId }) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchActivities = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await getRecentActivity(assistantId);
        // Convert ActivityLog to Activity
        const convertedActivities: Activity[] = data.map((log: ActivityLog) => ({
          summary: log.details,
          timestamp: log.timestamp
        }));
        setActivities(convertedActivities);
      } catch (error) {
        console.error('Error fetching activities:', error);
        setError('Failed to fetch recent activities. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, [assistantId]);


  if (loading) {
    return (
      <ActivityPaper>
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      </ActivityPaper>
    );
  }

  if (error) {
    return (
      <ActivityPaper>
        <ErrorMessage variant="body1">{error}</ErrorMessage>
      </ActivityPaper>
    );
  }

  return (
    <ActivityPaper>
      <Typography variant="h5" gutterBottom>Recent Activity</Typography>
      {activities.length === 0 ? (
        <Typography variant="body1" align="center">No recent activities found.</Typography>
      ) : (
        <List>
          {activities.map((activity, index) => (
            <ListItem key={index} divider>
              <ListItemText
                primary={activity.summary}
                secondary={new Date(activity.timestamp).toLocaleString()}
              />
            </ListItem>
          ))}
        </List>
      )}
    </ActivityPaper>
  );
};

export default RecentActivityFeed;