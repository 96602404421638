import React, { useState, useEffect } from 'react';
import { Container, Grid, Button, Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import TemplateSelectionBasic from './TemplateSelectionBasic';
import BasicInfoBasic from './BasicInfoBasic';
import VoiceSelectionBasic from './VoiceSelectionBasic';
import SkillBuilderBasic from './SkillBuilderBasic';
import ReviewBasic from './ReviewBasic';
import ProgressTracker from './ProgressTracker';
import SaveDraftButton, { loadSavedDraft } from './SaveDraftButton';
import AgentSummaryPanel from './AgentSummaryPanel';
import { AgentData, AgentTemplate, NewAgentData } from '@/types/AgentTypes';
import PhoneNumberAssignmentBasic from './PhoneNumberAssignmentBasic';
import { createNewAgent } from '@/api/api';
import { steps } from './ProgressTracker';
import { useAuth } from '../AuthContext';

const AgentBuilderBasic: React.FC = () => {
  const { user } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [agentData, setAgentData] = useState<AgentData>(() => loadSavedDraft() || {
    name: '',
    role: '',
    general_knowledge: '',
    skill: '',
    personality: '',
    highlight: '',
    voice_id: '',
    voice_sample_url: '',
    assistant_id: '',
  });
  const [isBuilding, setIsBuilding] = useState(false);
  const [isSetupComplete, setIsSetupComplete] = useState(false);

  useEffect(() => {
    const savedDraft = loadSavedDraft();
    if (savedDraft) {
      setAgentData(savedDraft);
    }
  }, []);

  useEffect(() => {
    if (user && user.organizationId) {
      setAgentData(prevData => ({ ...prevData, organization_id: user.organizationId }));
    }
  }, [user]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleTemplateSelect = (template: AgentTemplate) => {
    setAgentData({
      ...agentData,
      ...template,
    });
    handleNext();
  };

  const handleAgentDataChange = (newData: Partial<AgentData>) => {
    setAgentData((prevData) => ({ ...prevData, ...newData }));
  };

  const handleCreateAgent = async () => {
    setIsBuilding(true);
    try {
      console.log('Organization ID:', agentData.organization_id);
      console.log('Name:', agentData.name);
      console.log('Role:', agentData.role);
  
      if (!agentData.organization_id || !agentData.name || !agentData.role) {
        throw new Error('Missing required fields: organization_id, name, or role');
      }
  
      const newAgentData: NewAgentData = {
        organization_id: agentData.organization_id || '',
        name: agentData.name || '',
        role: agentData.role || '',
        skill: agentData.skill || '[]',  
        general_knowledge: agentData.general_knowledge || '',
        personality: agentData.personality || '[]',  
        voice_id: agentData.voice_id || '',
        highlight: agentData.highlight || '',
        gender: agentData.gender || '', // Add this line
      };
  
      console.log('Creating agent with data:', newAgentData);
  
      const createdAgent = await createNewAgent(newAgentData);
    console.log('Agent created successfully:', createdAgent);

    // Update agentData with the assistant_id returned from the created agent
    await new Promise<void>((resolve) => {
      setAgentData((prevData) => {
        const updatedData = {
          ...prevData,
          assistant_id: createdAgent.assistant_id,
        };
        resolve();
        return updatedData;
      });
    });

    // Move to the next step (Phone Number Assignment)
    console.log('Moving to Phone Number Assignment step');
    setActiveStep(5); // Directly set to step 5 (PhoneNumberAssignmentBasic)
    console.log('New active step:', 5);
  } catch (error) {
    console.error('Error creating agent:', error);
    // Don't throw the error, just log it
  } finally {
    setIsBuilding(false);
  }
};

const handleCompleteSetup = () => {
  // Here you can add any final processing or API calls needed to complete the setup
  setIsSetupComplete(true);
  // Optionally, you can clear the saved draft here
  localStorage.removeItem('agentDraft');
};

const FinalStep: React.FC = () => (
  <Box sx={{ textAlign: 'center', mt: 4 }}>
    <Typography variant="h5" gutterBottom>
      Agent Setup Complete!
    </Typography>
    <Typography variant="body1" paragraph>
      You have successfully created and set up your agent. Click the button below to finish the process.
    </Typography>
    <Button
      variant="contained"
      color="primary"
      onClick={handleCompleteSetup}
      size="large"
    >
      Complete Setup
    </Button>
  </Box>
);

const getStepContent = (step: number) => {
  console.log('Current step:', step);
  switch (step) {
    case 0:
      return <TemplateSelectionBasic onSelect={handleTemplateSelect} />;
    case 1:
      return <BasicInfoBasic agentData={agentData} onChange={handleAgentDataChange} />;
    case 2:
      return <VoiceSelectionBasic agentData={agentData} onChange={handleAgentDataChange} />;
    case 3:
      return <SkillBuilderBasic agentData={agentData} onChange={handleAgentDataChange} />;
    case 4:
      return <ReviewBasic agentData={agentData} onCreateAgent={handleCreateAgent} isBuilding={isBuilding} />;
    case 5:
      console.log('Rendering PhoneNumberAssignmentBasic');
      return <PhoneNumberAssignmentBasic agentData={agentData} onChange={handleAgentDataChange} onNext={handleNext} />;
    case 6:
      return <FinalStep />;
    default:
      console.log('Default case reached');
      return null;
  }
};

if (isSetupComplete) {
  return (
    <Box sx={{ textAlign: 'center', mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Congratulations!
      </Typography>
      <Typography variant="body1" paragraph>
        Your agent has been successfully set up and is ready to use.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        href="/" // Changed to go to homepage
        size="large"
        sx={{ mt: 2 }} // Added margin top for better spacing
      >
        Go to Homepage
      </Button>
    </Box>
  );
}

return (
  <Container maxWidth="lg">
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box sx={{ mt: 6, mb: 6 }}>
        <ProgressTracker activeStep={activeStep} />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          {getStepContent(activeStep)}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <SaveDraftButton agentData={agentData} />
            {activeStep > 0 && (
              <Button onClick={handleBack}>Back</Button>
            )}
            {activeStep < steps.length - 1 && activeStep !== 5 && (
              <Button variant="contained" color="primary" onClick={handleNext}>
                Next
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <AgentSummaryPanel agentData={agentData} />
        </Grid>
      </Grid>
    </motion.div>
  </Container>
);
};

export default AgentBuilderBasic;