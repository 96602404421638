import React from 'react';
import {
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
} from '@mui/material';
import { motion } from 'framer-motion';
import { AgentData } from '@/types/AgentTypes';
import AgentBuildingAnimation from './AgentBuildingAnimation';

interface ReviewBasicProps {
  agentData: AgentData;
  onCreateAgent: () => Promise<void>;
  isBuilding: boolean;
}

const ReviewBasic: React.FC<ReviewBasicProps> = ({ agentData, onCreateAgent, isBuilding }) => {
  if (isBuilding) {
    return (
      <AgentBuildingAnimation
        agentName={agentData.name || 'Agent'}
        voiceSampleUrl={agentData.voice_sample_url || ''}
      />
    );
  }


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h5" gutterBottom>
        Review Your Agent
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Basic Information
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Name" secondary={agentData.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Role" secondary={agentData.role} />
              </ListItem>
              <ListItem>
                <ListItemText primary="General Knowledge" secondary={agentData.general_knowledge} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Highlight" secondary={agentData.highlight} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Voice
            </Typography>
            <Typography>
              Selected Voice ID: {agentData.voice_id}
            </Typography>
          </Paper>
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Skills
            </Typography>
            <List>
              {JSON.parse(agentData.skill || '[]').map((skill: { name: string; description: string }, index: number) => (
                <ListItem key={index}>
                  <ListItemText primary={skill.name} secondary={skill.description} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
      <Button
  variant="contained"
  color="primary"
  size="large"
  onClick={onCreateAgent}
  disabled={isBuilding}
>
  Create Agent
</Button>
      </Box>
    </motion.div>
  );
};

export default ReviewBasic;