import React, { useState, useEffect } from 'react';
import { Typography, Paper, Chip, TextField, Button, Box, Autocomplete, CircularProgress, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { AgentData } from '@/types/AgentTypes';
import { getSkillRecommendations } from '@/services/aiRecommendationService';
import { motion, AnimatePresence } from 'framer-motion';

interface SkillBuilderProps {
  agentData: AgentData;
  onChange: (newData: Partial<AgentData>) => void;
}

interface Skill {
  name: string;
  category: string;
  scenario?: string;
}

const skillCategories = [
  "Call Handling",
  "Product Knowledge",
  "Objection Handling",
  "Empathy",
  "Problem Solving",
  "Technical Support",
  "Sales Techniques",
  "Customer Service",
  "Industry Knowledge"
];

const SkillBuilder: React.FC<SkillBuilderProps> = ({ agentData, onChange }) => {
  const [skills, setSkills] = useState<Skill[]>([]);
  const [newSkill, setNewSkill] = useState<Skill | null>(null);
  const [recommendations, setRecommendations] = useState<Skill[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  useEffect(() => {
    if (agentData.skill) {
      setSkills(JSON.parse(agentData.skill));
    }
    fetchRecommendations();
  }, [agentData.skill, agentData.role]);

  const fetchRecommendations = async () => {
    setLoading(true);
    try {
      const recs = await getSkillRecommendations(agentData.role || '', agentData.dealership_name || '');
      setRecommendations(recs.map(rec => ({ name: rec.skill, category: rec.category, scenario: rec.scenario })));
    } catch (error) {
      console.error('Error fetching recommendations:', error);
    }
    setLoading(false);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(skills);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSkills(items);
    updateSkills(items);
  };

  const handleAddSkill = (skillToAdd: Skill) => {
    const updatedSkills = [...skills, skillToAdd];
    setSkills(updatedSkills);
    updateSkills(updatedSkills);
    setNewSkill(null);

    if (recommendations.length === 0) {
      fetchRecommendations();
    }
  };

  const handleDeleteSkill = (index: number) => {
    const updatedSkills = skills.filter((_, i) => i !== index);
    setSkills(updatedSkills);
    updateSkills(updatedSkills);
  };

  const updateSkills = (updatedSkills: Skill[]) => {
    onChange({ skill: JSON.stringify(updatedSkills) });
  };

  const filteredSkills = selectedCategory
    ? skills.filter(skill => skill.category === selectedCategory)
    : skills;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h5" gutterBottom>
        Skill Builder
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Autocomplete
          value={newSkill}
          onChange={(_, value) => {
            if (value && typeof value !== 'string') {
              handleAddSkill(value);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = options.filter(option =>
              option.name.toLowerCase().includes(params.inputValue.toLowerCase()) ||
              option.category.toLowerCase().includes(params.inputValue.toLowerCase())
            );
            if (params.inputValue !== '' && !filtered.some(option => option.name === params.inputValue)) {
              filtered.push({
                name: params.inputValue,
                category: 'Custom',
                scenario: 'Custom scenario for this skill'
              });
            }
            return filtered;
          }}
          options={recommendations}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Add a new skill"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <Typography variant="body1">{option.name}</Typography>
              <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
                ({option.category})
              </Typography>
            </li>
          )}
        />
      </Box>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Filter by Category</InputLabel>
        <Select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value as string)}
        >
          <MenuItem value="">All Categories</MenuItem>
          {skillCategories.map((category) => (
            <MenuItem key={category} value={category}>{category}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="skills">
          {(provided) => (
            <Paper
              {...provided.droppableProps}
              ref={provided.innerRef}
              elevation={3}
              sx={{ p: 2, minHeight: 100 }}
            >
              <AnimatePresence>
                {filteredSkills.map((skill, index) => (
                  <Draggable key={skill.name} draggableId={skill.name} index={index}>
                    {(provided) => (
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                      >
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Tooltip title={skill.scenario || 'No scenario available'} arrow>
                            <Chip
                              label={
                                <Box display="flex" alignItems="center">
                                  <Typography variant="body2">{skill.name}</Typography>
                                  <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
                                    ({skill.category})
                                  </Typography>
                                </Box>
                              }
                              onDelete={() => handleDeleteSkill(index)}
                              sx={{ m: 0.5 }}
                            />
                          </Tooltip>
                        </div>
                      </motion.div>
                    )}
                  </Draggable>
                ))}
              </AnimatePresence>
              {provided.placeholder}
            </Paper>
          )}
        </Droppable>
      </DragDropContext>
    </motion.div>
  );
};

export default SkillBuilder;