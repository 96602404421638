import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Pagination } from '@mui/material';
import { fetchVoicesFromDB } from '@/api/api';

interface Voice {
  voice_name: string;
  type: string;
  description: string;
}

interface VoiceSelectionProps {
  userId: string;
}

const VoiceSelection: React.FC<VoiceSelectionProps> = ({ userId }) => {
  const [voices, setVoices] = useState<Voice[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const voicesPerPage = 10;

  useEffect(() => {
    const fetchVoices = async () => {
      try {
        const voicesData = await fetchVoicesFromDB(); // Removed userId argument
        setVoices(voicesData);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unknown error occurred');
        }
      }
    };

    fetchVoices();
  }, [userId]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // Calculate the voices to display on the current page
  const startIndex = (page - 1) * voicesPerPage;
  const currentVoices = voices.slice(startIndex, startIndex + voicesPerPage);

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        {error && <Typography className="text-red-500">{error}</Typography>}
        {currentVoices.map((voice) => (
          <Card key={voice.voice_name} className="shadow-lg rounded-lg">
            <CardContent className="p-4">
              <Typography variant="h6" className="font-semibold">{voice.voice_name}</Typography>
              <Typography variant="subtitle2" className="italic text-gray-500">{voice.type}</Typography>
              <Typography variant="body2" className="mt-2 text-gray-600">{voice.description}</Typography>
              <audio controls className="mt-4 w-full">
                <source src={`/assets/voice-samples/${voice.voice_name}.mp3`} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </CardContent>
          </Card>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        <Pagination
          count={Math.ceil(voices.length / voicesPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </div>
    </div>
  );
};

export default VoiceSelection;
