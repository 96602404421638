import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  List, 
  ListItem, 
  ListItemButton, 
  ListItemIcon, 
  ListItemText, 
  Box, 
  Typography,
  Drawer,
  Avatar,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import defaultAvatar from 'src/assets/images/divergent-agents-logo.png';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const drawerWidth = 240;
const collapsedDrawerWidth = 60;

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<{ isCollapsed: boolean }>(({ theme, isCollapsed }) => ({
  width: isCollapsed ? collapsedDrawerWidth : drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: isCollapsed ? collapsedDrawerWidth : drawerWidth,
    boxSizing: 'border-box',
    top: 64,
    height: 'calc(100% - 64px)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

interface Assistant {
  assistant_id: string;
  name: string;
  profileImage: string | null;
}

interface SidebarListProps {
  assistants: Assistant[];
  onAssistantClick: (assistantId: string) => void;
  onInitialAssistantClick: (assistantId: string) => void;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  onImageUpload: (assistantId: string, file: File) => void;
}

const SidebarList: React.FC<SidebarListProps> = ({ 
  assistants, 
  onAssistantClick, 
  onInitialAssistantClick, 
  isCollapsed, 
  onToggleCollapse 
}) => {
  const [error, setError] = useState<string | null>(null);
  const [initialSelectionMade, setInitialSelectionMade] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (assistants.length > 0 && !initialSelectionMade && location.pathname === '/') {
      onInitialAssistantClick(assistants[0].assistant_id);
      setInitialSelectionMade(true);
    }
  }, [assistants, onInitialAssistantClick, initialSelectionMade, location.pathname]);

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <StyledDrawer variant="permanent" anchor="left" isCollapsed={isCollapsed}>
      <Box sx={{ overflow: 'auto' }}>
        <IconButton onClick={onToggleCollapse} sx={{ m: 1 }}>
          {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
        <List>
          {assistants.map((assistant) => (
            <ListItem key={assistant.assistant_id} disablePadding>
              <ListItemButton onClick={() => onAssistantClick(assistant.assistant_id)}>
                <ListItemIcon>
                  <Avatar 
                    src={assistant.profileImage || defaultAvatar} 
                    alt={`Avatar for ${assistant.name}`} 
                  />
                </ListItemIcon>
                {!isCollapsed && <ListItemText primary={assistant.name} />}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </StyledDrawer>
  );
};

export default SidebarList;