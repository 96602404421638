import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Card, CardContent, CardActions, Button, Box, CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import WaveSurfer from 'wavesurfer.js';
import { AgentData } from '@/types/AgentTypes';
import { fetchVoicesFromDB } from '@/api/api';

interface Voice {
  voice_id: string;
  voice_name: string;
  gender: string;
  type: string;
  description: string;
}

interface VoiceSelectionProps {
  agentData: AgentData;
  onChange: (newData: Partial<AgentData>) => void;
}

const VoiceSelection: React.FC<VoiceSelectionProps> = ({ agentData, onChange }) => {
  const [voices, setVoices] = useState<Voice[]>([]);
  const [playingVoice, setPlayingVoice] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const waveformRefs = useRef<{ [key: string]: WaveSurfer | null }>({});

  useEffect(() => {
    const fetchVoices = async () => {
      try {
        setLoading(true);
        const voicesData = await fetchVoicesFromDB();
        setVoices(voicesData);
        setError(null);
      } catch (err) {
        setError('Failed to load voices. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchVoices();
  }, []);

  useEffect(() => {
    voices.forEach((voice) => {
      const wavesurfer = WaveSurfer.create({
        container: `#waveform-${voice.voice_id}`,
        waveColor: '#3f51b5', // Changed to a blue color
        progressColor: '#1a237e', // Darker blue for progress
        cursorColor: '#303f9f', // Another shade of blue for cursor
        height: 40,
        fillParent: true,
        barWidth: 2,
        barRadius: 3,
        cursorWidth: 1,
        barGap: 3,
      });
      wavesurfer.load(`/assets/voice-samples/${voice.voice_name}.mp3`);
      waveformRefs.current[voice.voice_id] = wavesurfer;

      return () => {
        wavesurfer.destroy();
      };
    });
  }, [voices]);

  const handleVoiceSelect = (voiceId: string) => {
    onChange({ voice_id: voiceId });
  };

  const handlePlaySample = (voiceId: string) => {
    if (playingVoice) {
      waveformRefs.current[playingVoice]?.stop();
    }
    if (playingVoice !== voiceId) {
      waveformRefs.current[voiceId]?.play();
      setPlayingVoice(voiceId);
    } else {
      setPlayingVoice(null);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h5" gutterBottom>
        Voice Selection
      </Typography>
      <Grid container spacing={3}>
        {voices.map((voice) => (
          <Grid item xs={12} sm={6} md={4} key={voice.voice_id}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Card 
                variant={agentData.voice_id === voice.voice_id ? "outlined" : "elevation"}
                sx={{ 
                  borderColor: agentData.voice_id === voice.voice_id ? 'primary.main' : 'transparent',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 3,
                  }
                }}
              >
                <CardContent>
                  <Typography variant="h6">{voice.voice_name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {`${voice.type} - ${voice.description}`}
                  </Typography>
                  <Box id={`waveform-${voice.voice_id}`} sx={{ mt: 2 }} />
                </CardContent>
                <CardActions>
                  <Button 
                    size="small" 
                    onClick={() => handlePlaySample(voice.voice_id)}
                  >
                    {playingVoice === voice.voice_id ? 'Pause' : 'Play Sample'}
                  </Button>
                  <Button 
                    size="small" 
                    onClick={() => handleVoiceSelect(voice.voice_id)}
                  >
                    Select Voice
                  </Button>
                </CardActions>
                {agentData.voice_id === voice.voice_id && (
                  <Box sx={{ p: 1, bgcolor: 'primary.main', color: 'primary.contrastText' }}>
                    <Typography variant="body2" align="center">Selected</Typography>
                  </Box>
                )}
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </motion.div>
  );
};

export default VoiceSelection;