import React, { useEffect, useState } from 'react';
import { UserMetrics, fetchUserMetrics, updateUserMetrics } from '@/api/api';
import { useTheme } from './ThemeContext';

const BillingSummary: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [metrics, setMetrics] = useState<UserMetrics[] | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        await updateUserMetrics(); // Update metrics before fetching
        const data = await fetchUserMetrics('all');
        setMetrics(Array.isArray(data) ? data : [data]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchMetrics();
  }, []);

  const renderMetricCard = (title: string, value: number, unit: string) => (
    <div className={`rounded-lg shadow p-6 text-center ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
      <h3 className={`text-lg font-semibold mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>{title}</h3>
      <p className={`text-3xl font-bold ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}>
        {value.toFixed(2)} <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>{unit}</span>
      </p>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className={`animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 ${isDarkMode ? 'border-blue-400' : 'border-blue-500'}`}></div>
      </div>
    );
  }

  if (!metrics || metrics.length === 0) {
    return (
      <div className={`shadow-lg rounded-lg p-6 text-center ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <svg className={`w-16 h-16 mx-auto mb-4 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <h2 className={`text-2xl font-bold mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>No Billing Summary Available</h2>
        <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>Usage data will appear here once activity is recorded.</p>
      </div>
    );
  }

  const totalDuration = metrics.reduce((sum, metric) => sum + (parseFloat(metric.total_duration as any) || 0), 0);
  const totalCalls = metrics.reduce((sum, metric) => sum + (parseInt(metric.call_count as any, 10) || 0), 0);
  const totalCost = metrics.reduce((sum, metric) => sum + (parseFloat(metric.total_cost as any) || 0), 0);

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {renderMetricCard("Monthly Total Minutes", totalDuration / 60, "minutes")}
      {renderMetricCard("Monthly Call Count", totalCalls, "calls")}
      {renderMetricCard("Monthly Total Cost", totalCost, "USD")}
    </div>
  );
};

export default BillingSummary;