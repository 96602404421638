import React, { useState, useEffect, useCallback } from 'react';
import { Tab, Box, Snackbar, Alert, CssBaseline, CircularProgress } from '@mui/material';
import { fetchAgentData, updateAgentData, fetchFileIds, fetchFileDetails, fetchVoicesFromDB, checkAuthStatus, fetchUserMetrics, fetchAndStoreMetrics, fetchUserAssistants, uploadFile, deleteFile } from 'src/api/api';
import { useTheme as useCustomTheme } from 'src/components/ThemeContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AgentProfileVoices from './AgentProfileVoices';
import { MetricsSummary, ChartsSection } from './AgentProfileCharts';
import AgentBasicInfo from './AgentBasicInfo';
import AgentKnowledgeBase from './AgentKnowledgeBase';
import RecentActivityFeed from './RecentActivityFeed';
import { AgentData, FormData, Voice, FileDetails, UserMetrics, AgentProfileProps } from 'src/types/AgentTypes';
import ReactConfetti from 'react-confetti';
import { StyledContainer, StyledPaper, StyledTabs } from './StyledComponents';

const AgentProfile: React.FC<AgentProfileProps> = ({ assistantId }) => {
  const { isDarkMode } = useCustomTheme();
  const [assistantData, setAssistantData] = useState<AgentData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    name: '',
    generalKnowledge: '',
    skill: '',
    personality: '',
    highlights: '',
    knowledgeBase: '',
    voice: '',
    gender: 'female',
  });
  const [metrics, setMetrics] = useState<UserMetrics[] | null>(null);
  const [voices, setVoices] = useState<Voice[]>([]);
  const [files, setFiles] = useState<FileDetails[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);
  const handleImageUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  }, []);
  const fetchAssistantData = useCallback(async () => {
    if (!assistantId) {
      setError('No assistant ID provided');
      return;
    }

    setLoading(true);
    try {
      const data = await fetchAgentData(assistantId);
      if (data) {
        setAssistantData(data);
        setFormData({
          name: data.name || '',
          generalKnowledge: data.general_knowledge || '',
          skill: data.skill || '',
          personality: data.personality || '',
          highlights: data.highlight || '',
          knowledgeBase: '',
          voice: data.voice_id || '',
          gender: data.gender || 'female',
        });
      } else {
        setError('No data received for assistant');
      }
    } catch (err) {
      console.error('Error fetching assistant data: ', err);
      setError('Failed to fetch assistant data: ' + (err instanceof Error ? err.message : String(err)));
    } finally {
      setLoading(false);
    }
  }, [assistantId]);

  const fetchMetricsData = useCallback(async () => {
    try {
      await fetchAndStoreMetrics();
      const metricsData = await fetchUserMetrics(assistantId);
      setMetrics(Array.isArray(metricsData) ? metricsData : [metricsData]);
    } catch (err) {
      console.error('Error fetching metrics: ', err);
      setError('Failed to fetch metrics');
    }
  }, [assistantId]);

  const fetchAssistantPhoneNumber = useCallback(async () => {
    try {
      const assistants = await fetchUserAssistants();
      const assistant = assistants.find((a) => a.id === assistantId);
      if (assistant && assistant.phone_numbers) {
        setPhoneNumbers(Array.isArray(assistant.phone_numbers) ? assistant.phone_numbers : [assistant.phone_numbers]);
      }
    } catch (err) {
      console.error('Error fetching assistant phone number: ', err);
      setError('Failed to fetch phone number');
    }
  }, [assistantId]);

  useEffect(() => {
    const fetchAllData = async () => {
      await fetchAssistantData();
      await fetchMetricsData();
      await fetchAssistantPhoneNumber();
      await fetchVoicesData();
      await fetchAgentFiles();
    };

    fetchAllData();
  }, [fetchAssistantData, fetchMetricsData, fetchAssistantPhoneNumber]);

  const fetchVoicesData = async () => {
    try {
      const voicesData = await fetchVoicesFromDB();
      setVoices(voicesData);
    } catch (err) {
      console.error('Error fetching voices: ', err);
      setError('Failed to fetch voices data');
    }
  };

  const fetchAgentFiles = async () => {
    try {
      const fileIds = await fetchFileIds(assistantId);
      const fileDetailsPromises = fileIds.map((fileId: string) => fetchFileDetails(fileId));
      const fileDetails = await Promise.all(fileDetailsPromises);
      setFiles(fileDetails);
    } catch (err) {
      console.error('Error fetching files: ', err);
      setError('Failed to fetch files');
    }
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleVoiceSelect = (voiceId: string) => {
    setFormData((prevData) => ({ ...prevData, voice: voiceId }));
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];
      try {
        const data = await uploadFile(file);
        setFormData((prev) => ({ ...prev, knowledgeBase: data.fileUrl }));
        await fetchAgentFiles();
      } catch (err) {
        console.error('Error uploading file: ', err);
        setError('Failed to upload file');
      }
    }
  };

  const handleDeleteFile = async (fileId: string) => {
    try {
      await deleteFile(fileId);
      await fetchAgentFiles();
    } catch (err) {
      console.error('Error deleting file: ', err);
      setError('Failed to delete file');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      const updatedData: Partial<AgentData> = {
        name: formData.name,
        general_knowledge: formData.generalKnowledge,
        skill: formData.skill,
        personality: formData.personality,
        highlight: formData.highlights,
        voiceid: formData.voice,
        gender: formData.gender,
        phone_numbers: phoneNumbers,
      };
      const updatedAgent = await updateAgentData(assistantId, updatedData);
      setAssistantData(prevData => prevData ? {...prevData, ...updatedAgent} : updatedAgent);
      setShowSuccessMessage(true);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000);
    } catch (err) {
      console.error('Error updating data: ', err);
      setError('Failed to update assistant data: ' + (err instanceof Error ? err.message : String(err)));
    } finally {
      setIsUpdating(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: { main: isDarkMode ? '#90caf9' : '#3f51b5' },
      secondary: { main: isDarkMode ? '#f48fb1' : '#f50057' },
      background: { default: isDarkMode ? '#303030' : '#f5f5f5' },
    },
    typography: { fontFamily: 'Roboto, Arial, sans-serif' },
    shape: { borderRadius: 8 },
  });

  if (error) {
    return (
      <StyledContainer>
        <Alert severity="error" variant="filled">{error}</Alert>
      </StyledContainer>
    );
  }

  if (loading) {
    return (
      <StyledContainer>
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress size={60} />
        </Box>
      </StyledContainer>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StyledContainer>
        {showConfetti && <ReactConfetti recycle={false} numberOfPieces={200} />}
        <StyledTabs value={selectedTab} onChange={handleTabChange} centered>
          <Tab label="Overview" />
          <Tab label="Voice Selection" />
          <Tab label="Knowledge Base" />
          <Tab label="Recent Activity" />
        </StyledTabs>

        <Box mt={3}>
        {selectedTab === 0 && (
            <>
              <AgentBasicInfo
                formData={formData}
                handleTextareaChange={handleTextareaChange}
                handleSubmit={handleSubmit}
                isUpdating={isUpdating}
                phoneNumbers={phoneNumbers}
                profileImage={profileImage}
                handleImageUpload={handleImageUpload}
              />
              {metrics && <ChartsSection metrics={metrics} />}
            </>
          )}

          {selectedTab === 1 && (
            <StyledPaper>
              <AgentProfileVoices
                voices={voices}
                selectedVoice={formData.voice}
                onVoiceSelect={handleVoiceSelect}
              />
            </StyledPaper>
          )}

          {selectedTab === 2 && (
            <AgentKnowledgeBase
              files={files}
              handleFileUpload={handleFileUpload}
              handleDeleteFile={handleDeleteFile}
            />
          )}

          {selectedTab === 3 && (
            <StyledPaper>
              <RecentActivityFeed assistantId={assistantId} />
              {metrics && <ChartsSection metrics={metrics} />}
            </StyledPaper>
          )}
        </Box>

        <Snackbar
          open={showSuccessMessage}
          autoHideDuration={6000}
          onClose={() => setShowSuccessMessage(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setShowSuccessMessage(false)}
            severity="success"
            sx={{
              width: '100%',
              backgroundColor: theme.palette.success.main,
              color: theme.palette.common.white,
            }}
          >
            Agent updated successfully!
          </Alert>
        </Snackbar>
      </StyledContainer>
    </ThemeProvider>
  );
};

export default AgentProfile;