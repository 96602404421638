import React, { useState, useEffect } from 'react';
import { Typography, Paper, Button } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { motion } from 'framer-motion';
import { AgentData } from '@/types/AgentTypes';
import { sendAgentWebhook } from '@/api/api';

interface WebhookPreviewProps {
  agentData: AgentData;
}

const WebhookPreview: React.FC<WebhookPreviewProps> = ({ agentData }) => {
  const [webhookPayload, setWebhookPayload] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [sendResult, setSendResult] = useState<string | null>(null);

  useEffect(() => {
    const payload = JSON.stringify(agentData, null, 2);
    setWebhookPayload(payload);
  }, [agentData]);

  const handleSendWebhook = async () => {
    setIsSending(true);
    setSendResult(null);
    try {
      if (!agentData.assistant_id) {
        throw new Error('Assistant ID is required');
      }
      const webhookData = {
        ...agentData,
        assistant_id: agentData.assistant_id
      };
      const result = await sendAgentWebhook(webhookData);
      setSendResult(`Webhook sent successfully: ${result}`);
    } catch (error) {
      setSendResult(`Error sending webhook: ${error instanceof Error ? error.message : String(error)}`);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h5" gutterBottom>
        Webhook Preview
      </Typography>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <SyntaxHighlighter language="json" style={tomorrow}>
          {webhookPayload}
        </SyntaxHighlighter>
      </Paper>
      <Button
        variant="contained"
        onClick={handleSendWebhook}
        disabled={isSending || !agentData.assistant_id}
      >
        {isSending ? 'Sending...' : 'Send Webhook'}
      </Button>
      {sendResult && (
        <Typography color={sendResult.includes('Error') ? 'error' : 'success'} sx={{ mt: 2 }}>
          {sendResult}
        </Typography>
      )}
    </motion.div>
  );
};

export default WebhookPreview;