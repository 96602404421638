import React from 'react';
import { Box, Toolbar } from '@mui/material';
import MainNav from '../components/MainNav';
import Settings from '../components/account/Settings';
import BillingPage from '../components/BillingPage/BillingPage';
import CallLogs from '../components/CallLogs';
import SalesCallAnalysis from '../components/SalesCallAnalysis';
import XtimeComponent from '../components/old_code/XtimeComponent';
import FileList from '../components/FileList';
import AdminLogs from '../components/admin/AdminLogs';
import AgentProfile from '../components/agentprofile/AgentProfile';
import CallMetrics from '../components/CallMetrics';
import { useParams } from 'react-router-dom';
import { SidebarListWrapper } from '../components/SidebarListWrapper';
import VoiceSelection from '@/components/VoiceSelection/VoiceSelection';

const LayoutWrapper: React.FC<{ children: React.ReactNode, showSidebar?: boolean }> = ({ children, showSidebar = false }) => (
  <Box sx={{ display: 'flex' }}>
    <MainNav />
    {showSidebar && <SidebarListWrapper />}
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      {children}
    </Box>
  </Box>
);

export const SettingsLayout: React.FC = () => (
  <LayoutWrapper>
    <Settings />
  </LayoutWrapper>
);

export const BillingLayout: React.FC = () => (
  <LayoutWrapper>
    <BillingPage />
  </LayoutWrapper>
);

export const CallLogsLayout: React.FC = () => (
  <LayoutWrapper showSidebar>
    <CallLogs />
  </LayoutWrapper>
);

export const SalesCallAnalysisLayout: React.FC = () => (
  <LayoutWrapper>
    <SalesCallAnalysis />
  </LayoutWrapper>
);

export const XtimeLayout: React.FC = () => (
  <LayoutWrapper>
    <XtimeComponent />
  </LayoutWrapper>
);

export const FilesLayout: React.FC = () => (
  <LayoutWrapper showSidebar>
    <FileList />
  </LayoutWrapper>
);

export const VoicesLayout: React.FC = () => (
  <LayoutWrapper showSidebar>
    <VoiceSelection userId={''} />
  </LayoutWrapper>
);

export const AdminLogsLayout: React.FC = () => (
  <LayoutWrapper>
    <AdminLogs />
  </LayoutWrapper>
);

export const AgentsLayout: React.FC = () => {
  const { assistantId } = useParams<{ assistantId: string }>();
  return (
    <LayoutWrapper showSidebar>
      {assistantId && <AgentProfile assistantId={assistantId} />}
      <CallMetrics />
    </LayoutWrapper>
  );
};

export default {
  SettingsLayout,
  BillingLayout,
  CallLogsLayout,
  SalesCallAnalysisLayout,
  XtimeLayout,
  FilesLayout,
  AdminLogsLayout,
  AgentsLayout,
  VoicesLayout
};