import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useParams } from 'react-router-dom';
import { CssBaseline, CircularProgress } from '@mui/material';
import { useTheme } from '../components/ThemeContext';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AuthProvider, useAuth } from '../components/AuthContext';
import { ProfileProvider } from '../components/account/ProfileContext';
import { lightTheme, darkTheme } from '../theme';
import MainLayout from '../layouts/MainLayout';
import MainNav from '../components/MainNav';
import {
  SettingsLayout,
  BillingLayout,
  CallLogsLayout,
  SalesCallAnalysisLayout,
  XtimeLayout,
  AgentsLayout,
  FilesLayout,
  AdminLogsLayout,
  VoicesLayout
} from '../layouts/LayoutComponents';
import AgentProfile from '@/components/agentprofile/AgentProfile';
import AdvancedAgentBuilder from '../components/agent_builder/AdvancedAgentBuilder'; // Import the component
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import AgentBuilder from '../components/agent_builder_basic/AgentBuilderBasic';
import OverviewPage from '../components/overview/OverviewPage';

const SignUp = lazy(() => import('../components/account/SignUp'));
const Login = lazy(() => import('../components/account/Login'));
const ResetPassword = lazy(() => import('../components/account/ResetPassword'));
const AdminPanel = lazy(() => import('../components/admin/AdminPanel'));
const NewCustomer = lazy(() => import('../components/account/NewCustomer'));
const CreateNewAgent = lazy(() => import('../components/CreateNewAgent'));
const VoiceSelection = lazy(() => import('../components/VoiceSelection/VoiceSelection'));
const PhoneNumberCreation = lazy(() => import('../components/PhoneNumberCreation'));


const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_AZURE_CLIENT_ID,
    authority: "https://login.microsoftonline.com/organizations",
    redirectUri: import.meta.env.VITE_AZURE_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);


const LoadingFallback: React.FC = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress />
  </div>
);

const ProtectedRoute: React.FC<{ children: React.ReactNode; adminOnly?: boolean }> = ({ children, adminOnly = false }) => {
  const { isAuthenticated, isLoading, isAdmin } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <LoadingFallback />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (adminOnly && !isAdmin) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

const AgentProfileRoute: React.FC = () => {
  const { assistantId } = useParams<{ assistantId: string }>();
  return <AgentProfile assistantId={assistantId || ''} />;
};

const AppRoutes: React.FC = () => (
  <Routes>
    <Route path="/signup" element={<SignUp />} />
    <Route path="/login" element={<Login />} />
    <Route path="/reset-password" element={<ResetPassword />} />
    <Route path="/" element={<ProtectedRoute><MainLayout /></ProtectedRoute>} />
    <Route path="/agent/:assistantId" element={<ProtectedRoute><MainLayout /></ProtectedRoute>} />
    <Route path="/settings" element={<ProtectedRoute><SettingsLayout /></ProtectedRoute>} />
    <Route path="/billing" element={<ProtectedRoute><BillingLayout /></ProtectedRoute>} />
    <Route path="/overview" element={<ProtectedRoute><OverviewPage /></ProtectedRoute>} />
    <Route path="/call-logs" element={<ProtectedRoute><CallLogsLayout /></ProtectedRoute>} />
    <Route path="/sales-call-analysis" element={<ProtectedRoute><SalesCallAnalysisLayout /></ProtectedRoute>} />
    <Route path="/xtime" element={<ProtectedRoute><XtimeLayout /></ProtectedRoute>} />
    <Route path="/agents" element={<ProtectedRoute><AgentsLayout /></ProtectedRoute>} />
    <Route path="/files" element={<ProtectedRoute><FilesLayout /></ProtectedRoute>} />
    <Route path="/voices" element={<ProtectedRoute><VoicesLayout /></ProtectedRoute>} />
    <Route path="/agent-builder" element={<ProtectedRoute><AgentBuilder /></ProtectedRoute>} />
    <Route path="/admin/new-agent" element={<ProtectedRoute adminOnly={true}><AdminPanel /></ProtectedRoute>} />
    <Route path="/admin/new-customer" element={<ProtectedRoute adminOnly={true}><NewCustomer /></ProtectedRoute>} />
    <Route path="/admin/logs" element={<ProtectedRoute adminOnly={true}><AdminLogsLayout /></ProtectedRoute>} />
    <Route path="/admin/advanced-agent-builder" element={<ProtectedRoute adminOnly={true}><AdvancedAgentBuilder /></ProtectedRoute>} />
    <Route path="/create-new-agent/:agentType" element={<ProtectedRoute><CreateNewAgent /></ProtectedRoute>} />
    <Route path="/phone-numbers" element={<ProtectedRoute><PhoneNumberCreation onNumberCreated={() => {}} /></ProtectedRoute>} />
  </Routes>
);

const App: React.FC = () => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <MsalProvider instance={msalInstance}>
          <AuthProvider>
            <ProfileProvider>
              <MainNav />
              <Suspense fallback={<LoadingFallback />}>
                <AppRoutes />
              </Suspense>
            </ProfileProvider>
          </AuthProvider>
        </MsalProvider>
      </MuiThemeProvider>
    </Router>
  );
};

export default App;