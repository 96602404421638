import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Grid, Paper } from '@mui/material';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { UserMetrics, fetchUserMetrics, updateUserMetrics } from '@/api/api';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const CallMetrics: React.FC = () => {
  const [metrics, setMetrics] = useState<UserMetrics[] | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        await updateUserMetrics(); // Update metrics before fetching
        const data = await fetchUserMetrics('all');
        setMetrics(Array.isArray(data) ? data : [data]);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!metrics || metrics.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography>No metrics data available</Typography>
      </Box>
    );
  }

  const totalCalls = metrics.reduce((sum, metric) => sum + (typeof metric.call_count === 'number' ? metric.call_count : parseInt(metric.call_count, 10)), 0);
  const totalCost = metrics.reduce((sum, metric) => sum + (typeof metric.total_cost === 'number' ? metric.total_cost : parseFloat(metric.total_cost)), 0);
  const totalDuration = metrics.reduce((sum, metric) => sum + (typeof metric.total_duration === 'number' ? metric.total_duration : parseFloat(metric.total_duration)), 0);
  const averageCallCost = totalCalls > 0 ? totalCost / totalCalls : 0;

  const dailyData = metrics.map(metric => ({
    date: new Date(metric.date).toLocaleDateString(), // Ensure this returns a string
    bill: parseFloat(metric.total_cost as unknown as string) // Ensure this is a number
  }));

  // You may need to adjust this based on your actual data
  const pieData = [
    { name: 'Pipeline Error', value: 3 },
    { name: 'No Microphone Permission', value: 2 },
    { name: 'Customer Ended Call', value: 11 },
    { name: 'Unknown Error', value: 1 },
  ];

  return (
    <Box sx={{ p: 3, bgcolor: '#1e1e1e', color: 'white' }}>
      <Typography variant="h4" gutterBottom>Overview</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, bgcolor: '#252525', color: 'white' }}>
            <Typography variant="h6">Total Minutes</Typography>
            <Typography variant="h4">{(totalDuration / 60).toFixed(2)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, bgcolor: '#252525', color: 'white' }}>
            <Typography variant="h6">Total Call Count</Typography>
            <Typography variant="h4">{totalCalls}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, bgcolor: '#252525', color: 'white' }}>
            <Typography variant="h6">Average Call Cost</Typography>
            <Typography variant="h4">${averageCallCost.toFixed(2)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, bgcolor: '#252525', color: 'white' }}>
            <Typography variant="h6">Total Cost</Typography>
            <Typography variant="h4">${totalCost.toFixed(2)}</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>Call Analysis</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2, bgcolor: '#252525', height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={dailyData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Area type="monotone" dataKey="bill" stroke="#8884d8" fill="#8884d8" />
                </AreaChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2, bgcolor: '#252525', height: 300 }}>
              <Typography variant="h6" gutterBottom>Reason Call Ended</Typography>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {pieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CallMetrics;