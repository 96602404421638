import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchUserProfile } from '@/api/api';
import { useAuth } from '../AuthContext';

interface ProfileContextType {
  profileImage: string;
  setProfileImage: (image: string) => void;
  refreshProfileImage: () => Promise<void>;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [profileImage, setProfileImage] = useState<string>('');
  const { user } = useAuth();

  const refreshProfileImage = async () => {
    try {
      const userProfile = await fetchUserProfile();
      if (userProfile.profilePicture) {
        setProfileImage(userProfile.profilePicture);
      } else {
        setProfileImage(''); // Set to empty string or a default image URL
      }
    } catch (error: any) {
      console.error('Failed to fetch profile image:', error);
      setProfileImage(''); // Set to empty string or a default image URL
    }
  };
  
  useEffect(() => {
    if (user) {
      refreshProfileImage();
    }
  }, [user]);

  return (
    <ProfileContext.Provider value={{ profileImage, setProfileImage, refreshProfileImage }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};