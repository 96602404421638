import axios from 'axios';
import * as CryptoJS from 'crypto-js';
import { User } from 'src/components/AuthContext';
import { NewAgentData, UserPlan, UserProfile, PhoneNumber, BuyPhoneNumberRequest } from '@/types/AgentTypes';
import { useAuth } from '@/components/AuthContext';

// Environment variables
const DB_URL = import.meta.env.VITE_DB_URL;
const MAKE_ENDPOINT = import.meta.env.VITE_MAKE_ENDPOINT;
const ELEVENLABS_API_KEY = import.meta.env.VITE_ELEVENLABS_API_KEY;
const ELEVENLABS_API_URL = 'https://api.elevenlabs.io/v1/voices';
const secretKey = import.meta.env.VITE_SECRET_KEY;
const VAPI_URL = import.meta.env.VITE_VAPI_URL;
const API_KEY = import.meta.env.VITE_VAPI_API_KEY;
const EMAIL_SUMMARY_WEBHOOK_URL = import.meta.env.VITE_EMAIL_SUMMARY_WEBHOOK_URL;

// Error handling function
const handleError = (error: unknown, message: string): never => {
  console.error(message, error);
  throw new Error(message);
};

// Create axios instance
const api = axios.create({
  baseURL: DB_URL, // Ensure this is set correctly
});

// Request interceptor to add token
api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
    console.log('Added token to request headers');
  } else {
    console.warn('No token available for request');
  }
  return config;
}, (error) => {
  console.error('Error in request interceptor:', error);
  return Promise.reject(error);
});

// Response interceptor for logging
api.interceptors.response.use(
  (response) => {
    console.log('Response received:', response.status, response.data);
    return response;
  },
  (error) => {
    console.error('Error in response:', error.response ? error.response.data : error.message);
    return Promise.reject(error);
  }
);

// Utility function to get token from localStorage
export const getToken = (): string | null => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token found in localStorage');
    return null;
  }
  console.log('Token retrieved from localStorage');
  return token;
};

// Utility function to decode JWT and extract user ID
export const decodeJWT = (token: string): any => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Failed to decode JWT:', error);
    return null;
  }
};

// Decryption function
const decryptData = (ciphertext: string): any => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  } catch (error) {
    console.error('Failed to decrypt data:', error);
    throw new Error('Failed to decrypt data');
  }
};

// Encryption function
export const encryptData = (data: Record<string, unknown>): string => {
  if (!data || typeof data !== 'object') {
    throw new Error('Invalid data for encryption');
  }
  try {
    console.log('Data to encrypt:', data);
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    console.log('Encrypted data:', encrypted);
    return encrypted;
  } catch (error) {
    console.error('Encryption error:', error);
    throw new Error('Failed to encrypt data');
  }
};

export default api;

const getUserIdFromToken = (): string | null => {
  const token = getToken();
  if (!token) {
    console.error('No token found');
    return null;
  }
  const decoded = decodeJWT(token);
  return decoded.userId;
};

// Register a new user
export const registerUser = async (userData: Record<string, unknown>): Promise<any> => {
  try {
    const response = await api.post('/api/auth/register', userData);
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to register user');
  }
};

// Other functions using the axios instance
export const createXTimeTool = async (dealerCode: string, defaultVin?: string, defaultOpcode?: string): Promise<any> => {
  try {
    const response = await makeVapiCall('tool', {
      async: false,
      messages: [
        {
          type: "request-start",
          content: "I'm checking the available service appointment times. This will just take a moment."
        },
        {
          type: "request-complete",
          content: "I've found the available appointment times."
        },
        {
          type: "request-failed",
          content: "I apologize, but I'm unable to retrieve the appointment times right now. There seems to be a technical issue."
        }
      ],
      type: "function",
      function: {
        name: "get_available_time_slots",
        description: "Retrieves available time slots for service appointments",
        parameters: {
          type: "object",
          properties: {
            dealerCode: {
              type: "string",
              description: "The unique code for the dealership"
            },
            vin: {
              type: "string",
              description: "The Vehicle Identification Number"
            },
            opcode: {
              type: "string",
              description: "The operation code for the service"
            },
            start: {
              type: "string",
              description: "The start date for the appointment search (YYYY-MM-DD)"
            },
            end: {
              type: "string",
              description: "The end date for the appointment search (YYYY-MM-DD)"
            }
          },
          required: ["dealerCode", "vin", "opcode"]
        }
      },
      server: {
        timeoutSeconds: 30,
        url: `${DB_URL}/api/xtime/available-time-slots`,
        secret: process.env.XTIME_SERVER_SECRET
      }
    });

    return response;
  } catch (error) {
    return handleError(error, 'Failed to create xTime tool');
  }
};


export interface UserMetrics {
  user_id: number;
  assistant_id: string;
  total_duration: number | string;
  total_cost: number | string;
  call_count: number | string;
  date: string;
}

export const fetchUserMetrics = async (assistantId: string): Promise<UserMetrics[]> => {
  try {
    const response = await api.get<UserMetrics[]>(`/api/assistants/metrics/${assistantId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user metrics:', error);
    throw error;
  }
};




export const updateUserMetrics = async (): Promise<void> => {
  try {
    await api.post('/api/billing/update-metrics');
  } catch (error) {
    console.error('Error updating user metrics:', error);
    throw error;
  }
};


export const assignToolToAssistant = async (assistantId: string, toolId: string, config: any): Promise<any> => {
  try {
    const response = await makeVapiCall('assignTool', {
      assistantId,
      toolId,
      config
    });

    return response;
  } catch (error) {
    return handleError(error, 'Failed to assign tool to assistant');
  }
};

// Function to fetch the user's plan ID
export const fetchUserPlanId = async (): Promise<string> => {
  try {
    const response = await api.get('/api/users/plan-id');
    return response.data.planId;
  } catch (error) {
    return handleError(error, 'Failed to fetch user plan ID');
  }
};

// Function to login a user
export const loginUser = async (userData: Record<string, unknown>): Promise<any> => {
  try {
    console.log('Sending login request with data:', userData);
    const response = await api.post('/api/auth/login', userData);
    console.log('Login response:', response.data);
    const data = response.data;

    if (data.token) {
      localStorage.setItem('token', data.token);
    }

    if (data.twoFactorRequired) {
      return { 
        twoFactorRequired: true, 
        email: userData.email as string,
        rememberMe: userData.rememberMe as boolean
      };
    }

    return {
      userId: data.userId,
      user: data.user,
      token: data.token,
      planId: data.planId // Add this line
    };
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};


export const logoutUser = async (): Promise<any> => {
  try {
    const response = await api.post('/api/auth/logout');
    localStorage.removeItem('token');
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to logout user');
  }
};

export interface AuthStatusResponse {
  isAuthenticated: boolean;
  userId?: number;
  user?: User;
}

// New function to check authentication status
export const checkAuthStatus = async (): Promise<AuthStatusResponse> => {
  try {
    const response = await api.get('/api/auth/status');
    return response.data;
  } catch (error) {
    console.error('Failed to check auth status:', error);
    return { isAuthenticated: false };
  }
};

// Function to initiate the demo call for an assistant
export const initiateDemoCall = async (assistantId: string, webCallUrl: string, webCallSipUri: string): Promise<any> => {
  try {
    const response = await api.post(`/api/assistants/${assistantId}/demo`, {
      webCallUrl,
      webCallSipUri,
    });
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to initiate demo call');
  }
};

export const setup2FA = async (): Promise<any> => {
  try {
    const response = await api.post('/api/auth/setup-2fa');
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to setup 2FA');
  }
};

export const getTwoFactorStatus = async () => {
  try {
    const response = await api.get('/api/auth/2fa-status');
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to get 2FA status');
  }
};

export const disable2FA = async (): Promise<any> => {
  try {
    const response = await api.post('/api/auth/disable-2fa');
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to disable 2FA');
  }
};

export const verify2FA = async (email: string, twoFactorCode: string, rememberMe?: boolean): Promise<any> => {
  try {
    const response = await api.post('/api/auth/verify-2fa', { email, twoFactorCode, rememberMe });
    const data = response.data;

    // Store the token in localStorage
    if (data.token) {
      localStorage.setItem('token', data.token);
    }

    return {
      userId: data.userId,
      user: data.user,
      token: data.token
    };
  } catch (error) {
    console.error('Failed to verify 2FA token', error);
    return handleError(error, 'Failed to verify 2FA token');
  }
};


export const connectMicrosoftAccount = async (accessToken: string): Promise<any> => {
  try {
    const response = await api.post('/api/auth/connect-microsoft', { accessToken });
    return response.data;
  } catch (error) {
    console.error('Microsoft account connection error:', error);
    throw error;
  }
};


export const checkMicrosoftConnectionStatus = async (): Promise<boolean> => {
  try {
    console.log('Checking Microsoft connection status');
    const response = await api.get('/api/auth/microsoft-connection-status');
    console.log('Microsoft connection status response:', response.data);
    return response.data.isConnected;
  } catch (error) {
    console.error('Error checking Microsoft connection status:', error);
    return handleError(error, 'Failed to check Microsoft connection status');
  }
};


export const microsoftLogin = async (accessToken: string): Promise<any> => {
  try {
    console.log('Sending Microsoft login request with token:', accessToken);
    const response = await api.post('/api/auth/microsoft', { accessToken });
    console.log('Microsoft login response:', response.data);
    
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
    }
    
    return {
      ...response.data,
      planId: response.data.planId // Add this line
    };
  } catch (error) {
    console.error('Microsoft authentication error:', error instanceof Error ? error.message : (error as any)?.response?.data || 'Unknown error');
    throw error;
  }
};

export interface Activity {
  summary: string;
  timestamp: string;
}

export interface AgentMetrics {
  assistant_id: string;
  total_calls: number;
  total_duration: number;
  average_call_duration: number;
  success_rate: number;
  date: string;
}


interface ActivityLog {
  id: number;
  user_id: number;
  activity_type: string;
  details: string;
  timestamp: string;
}



export const getRecentActivity = async (assistantId: string): Promise<ActivityLog[]> => {
  try {
    const response = await api.get(`/api/assistants/${assistantId}/recent-activity`);
    return response.data;
  } catch (error) {
    console.error('Error fetching recent activity:', error);
    throw error;
  }
};


export const fetchAgentMetrics = async (): Promise<AgentMetrics> => {
  try {
    const response = await api.get('/api/users/me/agent-metrics');
    return response.data;
  } catch (error) {
    console.error('Error fetching agent metrics:', error);
    throw error;
  }
};

export const fetchRecentActivity = async (): Promise<ActivityLog[]> => {
  try {
    const response = await api.get('/api/users/me/recent-activity');
    return response.data;
  } catch (error) {
    console.error('Error fetching recent activity:', error);
    throw error;
  }
};

export const fetchAndStoreMetrics = async (): Promise<{ message: string }> => {
  try {
    const response = await api.post('/api/assistants/metrics/fetch', {},);
    return response.data;
  } catch (error) {
    console.error('Error fetching and storing metrics:', error);
    throw error;
  }
};

export const getAgentMetrics = async (assistantId: string): Promise<AgentMetrics> => {
  try {
    const response = await api.get(`/api/assistants/metrics/${assistantId}`, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error('Error getting agent metrics:', error);
    throw error;
  }
};

// Fetch all call logs (or logs for a specific assistant)
export const fetchCallLogs = async (assistantId?: string): Promise<any[]> => {
  try {
    const url = assistantId ? `/api/call-logs?assistant_id=${assistantId}` : '/api/call-logs';
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("Error in fetchCallLogs:", error);
    throw error;
  }
};

// Fetch a single call log by ID
export const fetchCallLogById = async (callId: string): Promise<any> => {
  try {
    const response = await api.get(`/api/call-logs/${callId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch call log:', error);
    throw error;
  }
};

export const sendEmailSummaryWebhook = async (assistantId: string, emailSummaryEnabled: boolean, emailSummaryAddress: string): Promise<void> => {
  try {
    const response = await api.post(EMAIL_SUMMARY_WEBHOOK_URL, {
      assistantId,
      emailSummaryEnabled,
      emailSummaryAddress
    });
    if (!response.status) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error('Failed to send email summary webhook:', error);
    throw error;
  }
};


export const googleLogin = async (token: string): Promise<any> => {
  try {
    console.log('Sending Google login request with token:', token);
    const response = await api.post('/api/auth/google', { token });
    console.log('Google login response:', response.data);
    
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
    }
    
    return {
      ...response.data,
      planId: response.data.planId // Add this line
    };
  } catch (error) {
    console.error('Google authentication error:', error instanceof Error ? error.message : (error as any)?.response?.data || 'Unknown error');
    throw error;
  }
};

export const connectGoogleAccount = async (credential: string): Promise<any> => {
  try {
    const response = await api.post('/api/auth/connect-google', { credential });
    return response.data;
  } catch (error) {
    console.error('Google account connection error:', error);
    throw error;
  }
};

// Function to check if Google account is connected
export const checkGoogleConnectionStatus = async (): Promise<boolean> => {
  try {
    console.log('Checking Google connection status');
    const response = await api.get('/api/auth/google-connection-status');
    console.log('Google connection status response:', response.data);
    return response.data.isConnected;
  } catch (error) {
    console.error('Error checking Google connection status:', error);
    return handleError(error, 'Failed to check Google connection status');
  }
};

// Function to initiate Google OAuth flow
export const initiateGoogleOAuth = (): void => {
  window.location.href = `${DB_URL}/auth/google`;
};

// Function to handle Google OAuth callback
export const handleGoogleOAuthCallback = async (code: string): Promise<any> => {
  try {
    const response = await api.get(`/oauth2/callback?code=${code}`);
    const data = response.data;
    localStorage.setItem('token', data.token);
    return data;
  } catch (error) {
    return handleError(error, 'Failed to handle Google OAuth callback');
  }
};

// Fetch current user's information
export const fetchCurrentUser = async (): Promise<any> => {
  try {
    const response = await api.get('/api/users/me');
    const userData = response.data;
    if (userData.profile_picture) {
      userData.profile_picture = `${DB_URL}${userData.profile_picture}`;
    }
    return userData;
  } catch (error) {
    console.error("Error in fetchCurrentUser:", error);
    throw error;
  }
};

// Fetch user assistants from the database

// Define the Assistant type
export interface Assistant {
  id: any;
  assistant_id: string;
  name: string;
  phone_numbers: string[];
  profileImage: string | null;
}


export const updateAssistantProfileImage = async (assistantId: string, file: File): Promise<Assistant> => {
  const formData = new FormData();
  formData.append('image', file);

  try {
    const response = await api.post(`/api/assistants/${assistantId}/profile-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status !== 200) {
      throw new Error('Failed to update profile image');
    }

    return response.data;
  } catch (error) {
    console.error('Error updating profile image:', error);
    throw error;
  }
};


interface Log {
  log_type: 'login' | 'activity';
  id: number;
  user_id: number;
  details: string;
  timestamp: string;
}

export const fetchAdminLogs = async (): Promise<Log[]> => {
  try {
    const response = await api.get('/api/admin/logs');
    return response.data;
  } catch (error) {
    console.error('Error fetching admin logs:', error);
    throw error;
  }
};

export const fetchUserAssistants = async (): Promise<Assistant[]> => {
  try {
    const response = await api.get('/api/users/me/assistants');
    return response.data.map((assistant: any) => ({
      ...assistant,
      phone_numbers: assistant.phone_numbers || []
    }));
  } catch (error) {
    console.error("Error in fetchUserAssistants:", error);
    throw error;
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await api.get('/api/users/me');
    return response.data;
  } catch (error) {
    console.error("Error in getCurrentUser:", error);
    throw error;
  }
};

// Fetch assistant name
export const fetchAssistantName = async (assistantId: string): Promise<string> => {
  try {
    const response = await fetch(`${VAPI_URL}/assistant/${assistantId}`, {
      headers: {
        'Authorization': `Bearer ${API_KEY}`,
      },
      credentials: 'include',
    });
    if (!response.ok) {
      console.error(`Error fetching assistant name. Status: ${response.status}, StatusText: ${response.statusText}`);
      const errorBody = await response.text();
      console.error(`Error body: ${errorBody}`);
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data.name;
  } catch (error) {
    console.error('Error in fetchAssistantName:', error);
    throw error;
  }
};

export const createPhoneNumber = async (
  name: string,
  assistantId: string
): Promise<PhoneNumber> => {
  try {
    const response = await api.post('/api/assistants/phone-number', {
      name,
      assistantId,
    });
    return response.data; // Assuming response.data is a PhoneNumber object
  } catch (error) {
    console.error('Error creating phone number:', error);
    throw error;
  }
};



// Fetch agent data from the database
export const fetchAgentData = async (assistantId: string) => {
  try {
    const response = await api.get(`/api/assistants/${assistantId}`);
    console.log('Fetched agent data:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching agent data:', 
      error instanceof Error ? error.message : 
      (error as any)?.response?.data || 'Unknown error'
    );
    throw error;
  }
};






// New function for updating an agent (used by AdvancedAgentBuilder.tsx)
export const updateAgent = async (agentId: string, agentData: Partial<AgentData>): Promise<AgentData> => {
  try {
    const response = await api.put(`/api/assistants/${agentId}`, agentData);
    return response.data;
  } catch (error) {
    console.error('Error updating agent:', error);
    throw error;
  }
};

// Create agent data
export const createAgent = async (agentData: Partial<AgentData>): Promise<AgentData> => {
  try {
    const response = await api.post('/api/assistants', agentData);
    return response.data;
  } catch (error) {
    console.error('Error creating agent:', error);
    throw error;
  }
};

export const getUserPhoneNumbers = async (): Promise<PhoneNumber[]> => {
  const { user } = useAuth();
  
  if (!user || !user.id) {
    throw new Error('User not authenticated');
  }

  const response = await fetch(`/users/${user.id}/assistants`, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to fetch assistants: ${errorText}`);
  }

  const assistants: Assistant[] = await response.json();
  const phoneNumbers: PhoneNumber[] = assistants.flatMap((assistant) =>
    (assistant.phone_numbers || []).map((number: string) => ({
      id: parseInt(assistant.assistant_id),
      number,
      userId: parseInt(user.id),
      assistantId: assistant.assistant_id,
      status: 'active' as const,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      vapiId: '',
      name: `${assistant.name}'s number`,
      areaCode: number.substring(2, 5),
      monthlyCost: 0,
    }))
  );

  return phoneNumbers;
};

// Create a new customer and their account in the database
export const createCustomer = async (customerData: Record<string, unknown>): Promise<any> => {
  try {
    const accountResponse = await api.post('/accounts', customerData);
    const accountResult = accountResponse.data;

    const userData = {
      name: customerData.name as string,
      email: customerData.email as string,
      assistant_id: null,
      account_id: accountResult.account_id,
    };

    const userResponse = await api.post('/users', userData);
    return userResponse.data;
  } catch (error) {
    return handleError(error, 'Failed to create customer');
  }
};

interface AgentData {
  assistant_id: string;
  name?: string;
  role?: string;
  status?: string;
  date_updated?: string;
  skill?: string;
  general_knowledge?: string;
  personality?: string;
  voice_id?: string;
  highlight?: string;
  account_id?: number | null;
  assistant_demo?: string | null;
  transcriber_provider?: string;
  transcriber_model?: string;
  transcriber_language?: string;
  provider?: string;
  model?: string;
  messages?: string[];
  gender?: string;
  voice_provider?: string;
  voiceid?: string;
  phone_numbers?: string[];
  dealership_name?: string;
  dealership_website_url?: string;
  dealer_inventory_search_url_parameters?: string;
  enable_inventory_lookup_tool?: boolean;
  dealership_id?: number | null;
  dealership_address?: string;
  sales_target?: number | null;
  preferred_vehicle_brands?: string[];
}

// Function to send webhook with agent data
export const sendAgentWebhook = async (agentData: Partial<AgentData> & { assistant_id: string }): Promise<string> => {
  try {
    // Format the data to match the expected structure
    const formattedData = {
      assistant_id: agentData.assistant_id,
      name: agentData.name || '',
      general_knowledge: agentData.general_knowledge || '',
      skill: agentData.skill || '',
      personality: agentData.personality || '',
      highlight: agentData.highlight || '',
      voiceid: agentData.voiceid || '',
      gender: agentData.gender || '',
    };

    console.log('Sending webhook to:', MAKE_ENDPOINT);
    console.log('Webhook payload:', JSON.stringify(formattedData));

    const response = await api.post(MAKE_ENDPOINT, formattedData);
    const responseText = response.data;

    console.log('Webhook response:', responseText);
    return responseText;
  } catch (error) {
    console.error('Failed to send agent webhook:', error);
    throw error;
  }
};


// New function for sending webhook for AdvancedAgentBuilder
export const sendAdvancedAgentWebhook = async (agentData: AgentData): Promise<string> => {
  try {
    const ADVANCED_AGENT_WEBHOOK_URL = import.meta.env.VITE_ADVANCED_AGENT_WEBHOOK_URL;
    
    console.log('Sending webhook to:', ADVANCED_AGENT_WEBHOOK_URL);
    console.log('Webhook payload:', JSON.stringify(agentData));

    const response = await api.post(ADVANCED_AGENT_WEBHOOK_URL, agentData);
    const responseText = response.data;

    console.log('Webhook response:', responseText);
    return responseText;
  } catch (error) {
    console.error('Failed to send advanced agent webhook:', error);
    throw error;
  }
};



export interface UserPlanDetails {
  planName: string;
  planFeatures: string[];
  agentsCreated: number;
  agentLimit: number;
}

export const fetchUserPlanDetails = async (): Promise<UserPlanDetails> => {
  try {
    const response = await api.get('/api/users/plan-details');
    return response.data;
  } catch (error) {
    const err = error as Error; // Type assertion
    console.error('Error fetching user plan details:', err.message);
    throw err;
  }
};


// New function for fetching agent templates

interface AgentTemplate {
  name: string;
  role: string;
  skill: string;
  general_knowledge: string;
  personality: string;
  highlight: string;
  organization_id?: string | null;
}
// Add this new function for fetching agent templates
export const fetchAgentTemplates = async (): Promise<AgentTemplate[]> => {
  try {
    const response = await api.get('/api/agent-templates');
    return response.data;
  } catch (error) {
    console.error('Error fetching agent templates:', error);
    throw error;
  }
};


export const updateAgentData = async (agentId: string, updatedData: Partial<AgentData>): Promise<AgentData> => {
  console.log(`Updating agent ${agentId} with data:`, updatedData);

  if (!agentId) {
    console.error('Agent ID is missing or undefined');
    throw new Error('Agent ID is required');
  }

  if (!updatedData.name) {
    console.error('Name is missing or null in the updated data');
    throw new Error('Name is required and cannot be null');
  }

  const dataToEncrypt = {
    name: updatedData.name,
    general_knowledge: updatedData.general_knowledge,
    skill: updatedData.skill,
    personality: updatedData.personality,
    highlight: updatedData.highlight,
    voiceid: updatedData.voiceid,
    gender: updatedData.gender,
  };

  console.log('Data to encrypt:', dataToEncrypt);

  try {
    const encryptedData = encryptData(dataToEncrypt);
    console.log('Encrypted data:', encryptedData);
    const response = await api.put<AgentData>(`/api/assistants/${agentId}`, { encryptedData });
    console.log(`Successfully updated agent ${agentId}`, response.data);

    // Send webhook after successful update
    try {
      await sendAgentWebhook({
        ...updatedData,
        assistant_id: agentId
      });
    } catch (webhookError) {
      console.error('Error sending webhook:', webhookError);
    }

    return response.data;
  } catch (error) {
    console.error('Failed to update agent data:', error);
    throw error;
  }
};

export interface ClientData {
  id: number | null;
  email: string;
  dealership_name: string;
  dealership_website_url: string;
  address: string;
  dealership_id: number | null;
  first_name: string;
  last_name: string;
  country: string;
  state: string;
  zip: string;
  organization_id?: string | null;
}


export const getClientById = async (id: number): Promise<ClientData> => {
  try {
    const response = await api.get(`/api/clients/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching client:', error);
    throw error;
  }
};


export const createClient = async (clientData: Partial<ClientData>): Promise<ClientData> => {
  try {
    const response = await api.post('/api/clients/create', clientData);
    return response.data;
  } catch (error) {
    console.error('Error creating client:', error);
    throw error;
  }
};


export const searchClients = async (query: string): Promise<ClientData[]> => {
  try {
    const response = await api.get('/api/clients/search', { params: { query } });
    return response.data;
  } catch (error) {
    console.error('Error searching clients:', error);
    throw error;
  }
};


export const fetchPlans = async (): Promise<any> => {
  try {
    const response = await api.get('/api/newclient/plans');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch plans:', error);
    throw error;
  }
};

export const fetchDmsApiOptions = async (): Promise<any> => {
  try {
    const response = await api.get('/api/newclient/dms-api-options');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch DMS API options:', error);
    throw error;
  }
};

// Fetch voices from Eleven Labs API
export const fetchVoices = async (): Promise<any> => {
  try {
    const response = await fetch(ELEVENLABS_API_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'xi-api-key': ELEVENLABS_API_KEY,
      },
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    return handleError(error, 'Failed to fetch voices');
  }
};

// Upload file
export const uploadFile = async (file: File): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post('/api/files', formData);
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

// Fetch file IDs from the database
export const fetchFileIds = async (assistantId: string): Promise<string[]> => {
  try {
    const response = await api.get(`/api/assistants/${assistantId}/files`);
    return response.data.map((file: { file_id: string }) => file.file_id);
  } catch (error) {
    return handleError(error, 'Failed to fetch file IDs');
  }
};

// Function to fetch call summary
export const fetchCallSummary = async (assistantId: string, callId: string): Promise<any> => {
  try {
    const response = await api.get(`/api/call-logs/summary/${assistantId}/${callId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch call summary:', error);
    throw error;
  }
};

export const fetchAnalysisForCall = async (callId: string): Promise<any> => {
  try {
    const response = await api.get(`/api/sales-analysis/${callId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch analysis for call:', error);
    throw error;
  }
};

export const triggerSalesAnalysis = async (data: {
  call_id: string;
  assistant_id: string;
  summary: string;
  transcript: string;
}): Promise<any> => {
  if (!data.summary && !data.transcript) {
    console.log('No summary or transcript available for analysis.');
    return null;
  }

  try {
    const response = await api.post('/api/sales-analysis/trigger', data);
    return response.data;
  } catch (error) {
    console.error('Failed to trigger sales call analysis:', error);
    throw error;
  }
};

export interface SalesCallAnalysisData {
  call_id: string;
  caller_phone_number: string;
  summary: string | null;
  created_at: string;
  has_analysis: boolean;
  analysis?: any; // Make this optional
}

export const fetchSalesCallAnalysis = async (assistantId: string): Promise<SalesCallAnalysisData[]> => {
  try {
    const response = await api.get(`/api/sales-analysis?assistant_id=${assistantId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch sales call analyses:', error);
    throw error;
  }
};

export const fetchAssistantsForSalesAnalysis = async (): Promise<Assistant[]> => {
  try {
    const response = await api.get('/api/sales-analysis/assistants');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch assistants for sales analysis:', error);
    throw error;
  }
};

// Fetch file details
export const fetchFileDetails = async (fileId: string): Promise<any> => {
  try {
    const response = await api.get(`/api/files/${fileId}`);
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to fetch file details');
  }
};

// Delete file
export const deleteFile = async (fileId: string): Promise<void> => {
  try {
    await api.delete(`/api/files/${fileId}`);
  } catch (error) {
    return handleError(error, 'Failed to delete file');
  }
};

// Fetch all files uploaded by the user
export async function fetchUserFiles(): Promise<any[]> {
  try {
    const response = await api.get('/api/files');
    return response.data;
  } catch (error) {
    console.error('Error fetching user files:', error);
    throw error;
  }
}

// Fetch voices from the database
export const fetchVoicesFromDB = async (): Promise<any> => {
  try {
    const response = await api.get('/api/voices');
    return response.data;
  } catch (error) {
    console.error('Fetch error:', error);
    return handleError(error, 'Failed to fetch voices');
  }
};

// Function to change user password
export const changeUserPassword = async (passwordData: { oldPassword: string, newPassword: string }): Promise<any> => {
  try {
    const response = await api.post('/api/users/change-password', passwordData);
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to change password');
  }
};

// Function to reset user password
export const resetUserPassword = async (resetData: { token: string, newPassword: string }): Promise<any> => {
  try {
    const response = await api.post('/auth/reset-password', resetData);
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to reset password');
  }
};

// Function to initiate forgot password process
export const forgotPassword = async (email: string): Promise<any> => {
  try {
    const response = await api.post('/api/auth/forgot-password', { email });
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to initiate password reset');
  }
};

// Function to create a new user agent
export const createUserAgent = async (agentData: { name: string, systemContent: string, voice: string, planId: string }): Promise<any> => {
  try {
    const response = await api.post('/api/create-user-agent', agentData);
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to create user agent');
  }
};

// Function to make a VAPI call through the proxy
export const makeVapiCall = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await api.post('/proxy-vapi-call', { endpoint, data });
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to make VAPI call');
  }
};

// Function to get billing info for an account
export const getBillingInfo = async (accountId: string): Promise<any> => {
  try {
    const response = await api.get(`/api/billing/accounts/${accountId}/billing`);
    return response.data;
  } catch (error) {
    console.error('Failed to get billing info:', error);
    throw error;
  }
};

// Function to update billing info for an account
export const updateBillingInfo = async (accountId: string, billingData: any): Promise<any> => {
  try {
    const response = await api.put(`/api/accounts/${accountId}/billing`, billingData);
    return response.data;
  } catch (error) {
    console.error('Failed to update billing info:', error);
    throw error;
  }
};

export const uploadProfilePicture = async (formData: FormData): Promise<{ message: string, filePath: string }> => {
  try {
    const response = await api.post('/api/files/upload-profile-picture', formData);
    return response.data;
  } catch (error) {
    console.error('Error uploading profile picture:', error);
    throw error;
  }
};

export const fetchUserProfile = async (): Promise<UserProfile> => {
  try {
    const response = await api.get('/api/users/profile');
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

export interface BillingMetrics {
  total: string;
  avgDailyMinutes: string;
  callCount: number;
  billDailyBreakdown?: { [key: string]: number };
  callMinutesDailyBreakdown?: { [key: string]: number };
  callCountDailyBreakdown?: { [key: string]: number };
}

export const fetchBillingMetrics = async (rangeStart: string, rangeEnd: string): Promise<BillingMetrics> => {
  try {
    const response = await api.get(`/api/billing/metrics?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`);
    const data = response.data;
    
    const billingMetrics: BillingMetrics = {
      total: data.total,
      avgDailyMinutes: data.avgDailyMinutes,
      callCount: data.callCount,
      billDailyBreakdown: data.billDailyBreakdown || {},
      callMinutesDailyBreakdown: data.callMinutesDailyBreakdown || {},
      callCountDailyBreakdown: data.callCountDailyBreakdown || {},
    };

    return billingMetrics;
  } catch (error) {
    console.error('Failed to fetch billing metrics:', error);
    throw error;
  }
};

// Function to get invoices for an account
export const getInvoices = async (accountId: string): Promise<any> => {
  try {
    const response = await api.get(`/api/billing/accounts/${accountId}/invoices`);
    return response.data;
  } catch (error) {
    console.error('Failed to get invoices:', error);
    throw error;
  }
};



// Function to check admin status
export const checkAdminStatus = async (): Promise<boolean> => {
  try {
    const response = await api.get('/api/auth/admin-status');
    return response.data.isAdmin;
  } catch (error) {
    console.error('Failed to check admin status:', error);
    throw error;
  }
};

// Function to create a payment intent (for Stripe)
export const createPaymentIntent = async (
  amount: number, 
  currency: string, 
  paymentMethodTypes: string[] = ['card', 'us_bank_account']
): Promise<any> => {
  try {
    const response = await api.post('/api/billing/create-payment-intent', { amount, currency, payment_method_types: paymentMethodTypes });
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to create payment intent');
  }
};

// Function to create a crypto charge (for Coinbase Commerce)
export const createCryptoCharge = async (amount: number, currency: string): Promise<any> => {
  try {
    const response = await api.post('/api/billing/create-crypto-charge', { amount, currency });
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to create crypto charge');
  }
};

export const createNewAgent = async (agentData: NewAgentData): Promise<any> => {
  try {
    const response = await api.post('/api/assistants/create-new', agentData);
    return response.data;
  } catch (error) {
    return handleError(error, 'Failed to create new agent');
  }
};

export const fetchUserOrganizationId = async (): Promise<string> => {
  try {
    const token = getToken();
    if (!token) {
      throw new Error('No authentication token found');
    }

    console.log('Fetching user organization');

    const response = await api.get('/api/users/organization');
    console.log('User organization response:', response.data);

    const { organizationId } = response.data;

    if (!organizationId) {
      throw new Error('Organization ID not found in response');
    }

    return organizationId;
  } catch (error) {
    console.error('Error fetching user organization ID:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to fetch user organization ID: ${error.message}`);
    } else {
      throw new Error('Failed to fetch user organization ID: Unknown error');
    }
  }
};


export const fetchAllPhoneNumbers = async (): Promise<PhoneNumber[]> => {
  try {
    const response = await api.get('/api/phone-numbers');
    return response.data;
  } catch (error) {
    console.error('Error fetching all phone numbers:', error);
    throw error;
  }
};

export const fetchUserPhoneNumbers = async (): Promise<PhoneNumber[]> => {
  try {
    const response = await api.get('/api/users/me/phone-numbers');
    return response.data;
  } catch (error) {
    console.error('Error fetching user phone numbers:', error);
    throw error;
  }
};

export const assignPhoneNumber = async (phoneNumberId: number, assistantId: string): Promise<void> => {
  try {
    await api.post('/api/phone-numbers/assign', { phoneNumberId, assistantId }, { withCredentials: true });
  } catch (error) {
    console.error('Error assigning phone number:', error);
    throw error;
  }
};

export const unassignPhoneNumber = async (phoneNumberId: number): Promise<void> => {
  try {
    await api.post('/api/phone-numbers/unassign', { phoneNumberId }, { withCredentials: true });
  } catch (error) {
    console.error('Error unassigning phone number:', error);
    throw error;
  }
};

export const buyPhoneNumber = async (request: BuyPhoneNumberRequest): Promise<PhoneNumber> => {
  try {
    const response = await api.post<PhoneNumber>('/api/phone-numbers/create', request);
    return response.data;
  } catch (error) {
    console.error('Error buying phone number:', error);
    throw error;
  }
};