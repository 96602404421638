import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardActions, Button, Typography, Grid, Avatar, CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import { AgentTemplate } from '@/types/AgentTypes';
import { fetchAgentTemplates } from '@/api/api';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CodeIcon from '@mui/icons-material/Code';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HomeIcon from '@mui/icons-material/Home';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'; 

interface TemplateSelectionBasicProps {
  onSelect: (template: AgentTemplate) => void;
}

const iconMap: { [key: string]: React.ReactElement } = {
  'Customer Service': <SupportAgentIcon />,
  'Sales': <ShoppingCartIcon />,
  'Technical Support': <CodeIcon />,
  'Food Service': <RestaurantIcon />,
  'Real Estate': <HomeIcon />,
  'Financial Services': <AttachMoneyIcon />,
  'Service Agent': <DirectionsCarIcon />,
  'Sales Agent': <DirectionsCarIcon />,
  'BDC Agent': <DirectionsCarIcon />,
};

const templates: AgentTemplate[] = [
  {
    name: 'Customer Service Agent',
    role: 'Customer Service',
    skill: 'Skilled in resolving customer inquiries and issues promptly',
    general_knowledge: 'Strong understanding of customer service processes, company policies, and issue resolution techniques',
    personality: 'Empathetic, patient, and communicative',
    highlight: 'Excels at de-escalating situations and ensuring customer satisfaction',
  },
  {
    name: 'Technical Support Agent',
    role: 'Technical Support',
    skill: 'Proficient in troubleshooting technical issues and providing step-by-step guidance',
    general_knowledge: 'Deep knowledge of technical systems, software troubleshooting, and hardware diagnostics',
    personality: 'Analytical, calm under pressure, and detail-oriented',
    highlight: 'Specializes in guiding non-technical users through complex troubleshooting steps',
  },
  {
    name: 'Food Service Agent',
    role: 'Food Service',
    skill: 'Experienced in managing customer orders, dietary preferences, and resolving complaints',
    general_knowledge: 'Knowledgeable in menu options, food safety regulations, and restaurant operations',
    personality: 'Friendly, fast-paced, and service-oriented',
    highlight: 'Known for delivering excellent customer service while managing high-volume orders',
  },
  {
    name: 'Real Estate Agent',
    role: 'Real Estate',
    skill: 'Proficient in property listings, real estate market trends, and client consultation',
    general_knowledge: 'Comprehensive understanding of property valuation, real estate laws, and market analysis',
    personality: 'Persuasive, confident, and highly organized',
    highlight: 'Specializes in matching clients with their ideal properties while navigating complex negotiations',
  },
  {
    name: 'Financial Services Agent',
    role: 'Financial Services',
    skill: 'Expert in financial planning, investment strategies, and client advisory',
    general_knowledge: 'In-depth knowledge of financial markets, tax laws, and investment options',
    personality: 'Detail-oriented, trust-building, and financially savvy',
    highlight: 'Specializes in providing tailored financial advice that aligns with clients’ long-term goals',
  },
  {
    name: 'Service Agent',
    role: 'Service Agent',
    skill: 'Expert in automotive service and maintenance',
    general_knowledge: 'Comprehensive understanding of vehicle diagnostics and repair procedures',
    personality: 'Friendly, patient, and detail-oriented',
    highlight: 'Specializes in explaining complex technical issues in simple terms',
  },
  {
    name: 'Automotive Sales Agent',
    role: 'Sales Agent',
    skill: 'Proficient in automotive sales techniques and product knowledge',
    general_knowledge: 'In-depth understanding of various car models, features, and market trends',
    personality: 'Charismatic, persuasive, and customer-focused',
    highlight: 'Excels at matching customers with their ideal vehicles',
  },
  {
    name: 'BDC Agent',
    role: 'BDC Agent',
    skill: 'Expertise in customer relationship management and lead qualification',
    general_knowledge: 'Broad understanding of dealership operations and customer service best practices',
    personality: 'Professional, organized, and excellent communicator',
    highlight: 'Specializes in efficiently managing and prioritizing customer inquiries',
  },
];

const TemplateSelectionBasic: React.FC<TemplateSelectionBasicProps> = ({ onSelect }) => {
  const [fetchedTemplates, setFetchedTemplates] = useState<AgentTemplate[]>(templates);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const fetchedTemplates = await fetchAgentTemplates();
        setFetchedTemplates(fetchedTemplates);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching templates:', err);
        setError('Failed to load templates. Please try again later.');
        setLoading(false);
      }
    };

    loadTemplates();
  }, []);

  const handleSelectTemplate = (template: AgentTemplate) => {
    const updatedTemplate = {
      ...template,
      skill: JSON.stringify([{ name: template.skill, description: '' }]),
      personality: JSON.stringify(template.personality.split(', ').map(trait => trait.trim()))
    };
    onSelect(updatedTemplate);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Grid container spacing={3}>
      {fetchedTemplates.map((template, index) => (
        <Grid item xs={12} sm={6} md={4} key={template.name}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flexGrow: 1 }}>
                <Avatar sx={{ bgcolor: 'primary.main', mb: 2 }}>
                  {iconMap[template.role] || <SupportAgentIcon />}
                </Avatar>
                <Typography variant="h5" component="div" gutterBottom>
                  {template.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  {template.highlight}
                </Typography>
                <Typography variant="subtitle2" color="primary" gutterBottom>
                  Key Features:
                </Typography>
                <ul>
                  {template.skill.split(',').map((feature, idx) => (
                    <li key={idx}>
                      <Typography variant="body2">{feature.trim()}</Typography>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardActions>
                <Button 
                  size="small" 
                  onClick={() => handleSelectTemplate(template)}
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 'auto',
                    transition: 'all 0.3s',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: 3,
                    }
                  }}
                >
                  Select Template
                </Button>
              </CardActions>
            </Card>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
};

export default TemplateSelectionBasic;
