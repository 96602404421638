import React, { useState, useEffect } from 'react';
import {
  Box, CssBaseline, AppBar, Toolbar, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
  Divider, IconButton, SwipeableDrawer, Switch, Typography, Avatar
} from '@mui/material';
import OverviewIcon from '@mui/icons-material/Dashboard';
import AgentsIcon from '@mui/icons-material/People';
import FilesIcon from '@mui/icons-material/Folder';
import BillingIcon from '@mui/icons-material/Receipt';
import CallLogIcon from '@mui/icons-material/Phone';
import VoicesIcon from '@mui/icons-material/RecordVoiceOver';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ScheduleIcon from '@mui/icons-material/Schedule';
import BuildIcon from '@mui/icons-material/Build';
import { useNavigate } from 'react-router-dom';
import { useTheme } from './ThemeContext';
import { useProfile } from 'src/components/account/ProfileContext';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import { fetchUserAssistants } from '@/api/api';
import { useAuth } from './AuthContext';
import { SidebarListWrapper } from './SidebarListWrapper';

interface CustomListItemProps {
  onClick: () => void;
  IconComponent: React.ElementType;
  text: string;
  nested?: boolean;
}

const CustomListItem: React.FC<CustomListItemProps> = ({ onClick, IconComponent, text, nested = false }) => {
  const muiTheme = useMuiTheme();
  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={onClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          pl: nested ? 4 : 2,
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: muiTheme.palette.action.hover,
          },
        }}
      >
        <ListItemIcon>
          <IconComponent sx={{ mr: 2, fontSize: 24 }} />
        </ListItemIcon>
        <ListItemText primary={text} primaryTypographyProps={{ fontSize: 16 }} />
      </ListItemButton>
    </ListItem>
  );
};

function MainNav() {
  const { isDarkMode, toggleTheme } = useTheme();
  const muiTheme = useMuiTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, user, logout, isAdmin } = useAuth();
  const { profileImage, refreshProfileImage } = useProfile();

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        await refreshProfileImage();
        console.log('Profile image fetched:', profileImage);
      } catch (error) {
        console.error('Error fetching profile image:', error);
      }
    };

    fetchProfileImage();
  }, [refreshProfileImage, profileImage]);

  const handleAssistantClick = async () => {
    try {
      const assistants = await fetchUserAssistants();
      if (assistants.length > 0) {
        const firstAssistantId = assistants[0].id;
        navigate(`/agent/${firstAssistantId}`);
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error('Error fetching assistants:', error);
      navigate('/');
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  if (!isAuthenticated) {
    return null;
  }

  const mainNavItems = [
    { text: 'Overview', icon: OverviewIcon, path: '/overview' },
    { text: 'Agents', icon: AgentsIcon, path: '/agents' },
    { text: 'Files', icon: FilesIcon, path: '/files' },
    { text: 'Billing', icon: BillingIcon, path: '/billing' },
    { text: 'Call Log', icon: CallLogIcon, path: '/call-logs' },
    { text: 'Voices', icon: VoicesIcon, path: '/voices' },
    { text: 'APIs', icon: ScheduleIcon, path: '/xtime' },
    { text: 'Agent Builder', icon: BuildIcon, path: '/agent-builder' },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{ height: '64px', justifyContent: 'space-between' }}>
          <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 'bold' }}>
            Divergent Agents
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={profileImage || undefined}
              alt="Profile Image"
              sx={{
                width: 48,
                height: 48,
                border: `2px solid ${muiTheme.palette.background.paper}`,
                boxShadow: muiTheme.shadows[3],
                mr: 2,
              }}
            />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={() => setOpenDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        PaperProps={{
          sx: {
            width: '280px',
            backgroundColor: muiTheme.palette.background.default,
            color: muiTheme.palette.text.primary,
          },
        }}
        variant="temporary"
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
      >
        <Toolbar />
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 2 }}>
          <List>
            {mainNavItems.map((item) => (
              <CustomListItem
                key={item.text}
                onClick={() => {
                  navigate(item.path);
                  setOpenDrawer(false);
                }}
                IconComponent={item.icon}
                text={item.text}
              />
            ))}
          </List>
          <Box sx={{ mt: 'auto' }}>
            <Divider sx={{ my: 2 }} />
            <List>
              {isAuthenticated ? (
                <>
                  <CustomListItem onClick={() => navigate('/settings')} IconComponent={SettingsIcon} text="Settings" />
                  <CustomListItem onClick={() => navigate('/account')} IconComponent={AccountCircleIcon} text="Account" />
                  {isAdmin && (
                    <CustomListItem onClick={() => navigate('/admin/new-agent')} IconComponent={AdminPanelSettingsIcon} text="Admin Panel" />
                  )}
                  <CustomListItem onClick={handleLogout} IconComponent={AccountCircleIcon} text="Logout" />
                </>
              ) : (
                <>
                  <CustomListItem onClick={() => navigate('/signup')} IconComponent={AccountCircleIcon} text="Sign Up" />
                  <CustomListItem onClick={() => navigate('/login')} IconComponent={AccountCircleIcon} text="Login" />
                </>
              )}
              <ListItem>
                <ListItemIcon>
                  <Switch checked={isDarkMode} onChange={toggleTheme} />
                </ListItemIcon>
                <ListItemText primary="Dark Mode" primaryTypographyProps={{ fontSize: 16 }} />
              </ListItem>
            </List>
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

export default MainNav;