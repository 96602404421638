import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  Paper,
} from '@mui/material';
import { fetchUserPlanDetails, fetchRecentActivity, fetchAgentMetrics } from '@/api/api';
import UserPlanDisplay from './UserPlanDisplay';
import RecentActivity from './RecentActivity';
import MetricsDisplay from './MetricsDisplay';
import { UserPlan, ActivityLog, AgentMetrics } from '@/types/AgentTypes';

const OverviewPage: React.FC = () => {
  const [userPlan, setUserPlan] = useState<UserPlan | null>(null);
  const [recentActivity, setRecentActivity] = useState<ActivityLog[]>([]);
  const [agentMetrics, setAgentMetrics] = useState<AgentMetrics | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadOverviewData = async () => {
      try {
        const [planDetails, activity, metrics] = await Promise.all([
          fetchUserPlanDetails(),
          fetchRecentActivity(),
          fetchAgentMetrics(),
        ]);
        setUserPlan(planDetails);
        setRecentActivity(activity.map(act => ({ ...act, id: act.id.toString() })));
        setAgentMetrics(metrics);
      } catch (error) {
        console.error('Failed to load overview data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadOverviewData();
  }, []);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard Overview
      </Typography>
      <Grid container spacing={4}>
        {/* User Plan Details */}
        <Grid item xs={12} md={6}>
          {userPlan ? (
            <UserPlanDisplay {...userPlan} />
          ) : (
            <Typography variant="body1">No plan details available.</Typography>
          )}
        </Grid>

        {/* Recent Activity */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 3, borderRadius: 2, boxShadow: 3 }}>
            <Typography variant="h6" gutterBottom>
              Recent Activity
            </Typography>
            {recentActivity.length > 0 ? (
              <RecentActivity activities={recentActivity} />
            ) : (
              <Typography variant="body1">No recent activity available.</Typography>
            )}
          </Paper>
        </Grid>

        {/* Agent Metrics */}
        <Grid item xs={12}>
          <Paper sx={{ padding: 3, borderRadius: 2, boxShadow: 3 }}>
            <Typography variant="h6" gutterBottom>
              Agent Metrics
            </Typography>
            {agentMetrics ? (
              <MetricsDisplay metrics={agentMetrics} />
            ) : (
              <Typography variant="body1">No metrics available.</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OverviewPage;
