import React, { useState, useEffect } from 'react';
import { Typography, Paper, Chip, TextField, Button, Box, Autocomplete, CircularProgress } from '@mui/material';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { AgentData } from '@/types/AgentTypes';
import { getSkillRecommendations } from '@/services/aiRecommendationService';
import { motion, AnimatePresence } from 'framer-motion';
import Lottie from 'lottie-react';
import loadingAnimation from '@/assets/loading-animation-skills.json';

interface SkillBuilderProps {
  agentData: AgentData;
  onChange: (newData: Partial<AgentData>) => void;
}

interface Skill {
  id: string;
  name: string;
  category: string;
  scenario?: string;
}

const SkillBuilder: React.FC<SkillBuilderProps> = ({ agentData, onChange }) => {
  const [skills, setSkills] = useState<Skill[]>([]);
  const [newSkill, setNewSkill] = useState<Skill | null>(null);
  const [recommendations, setRecommendations] = useState<Skill[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastFetchedRole, setLastFetchedRole] = useState<string>('');

  useEffect(() => {
    if (agentData.skill) {
      setSkills(JSON.parse(agentData.skill));
    }
    if (agentData.role && agentData.role !== lastFetchedRole) {
      fetchRecommendations();
      setLastFetchedRole(agentData.role);
    }
  }, [agentData.skill, agentData.role]);

  const fetchRecommendations = async () => {
    setIsLoading(true);
    try {
      const recs = await getSkillRecommendations(agentData.role || '', agentData.dealership_name || '');
      setRecommendations(recs.map((rec, index) => ({ 
        id: `rec-${index}`,
        name: rec.skill, 
        category: rec.category, 
        scenario: rec.scenario 
      })));
    } catch (error) {
      console.error('Error fetching recommendations:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(skills);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSkills(items);
    updateSkills(items);
  };

  const handleAddSkill = (skillToAdd: Skill) => {
    if (skills.length >= 6) {
      alert('You can only add up to 6 skills.');
      return;
    }
    const updatedSkills = [...skills, { ...skillToAdd, id: `skill-${Date.now()}` }];
    setSkills(updatedSkills);
    updateSkills(updatedSkills);
    setNewSkill(null);
  };

  const handleDeleteSkill = (skillId: string) => {
    const updatedSkills = skills.filter((skill) => skill.id !== skillId);
    setSkills(updatedSkills);
    updateSkills(updatedSkills);
  };

  const updateSkills = (updatedSkills: Skill[]) => {
    onChange({ skill: JSON.stringify(updatedSkills) });
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
        <Lottie 
          animationData={loadingAnimation}
          style={{ width: 200, height: 200 }}
        />
      </Box>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h5" gutterBottom>
        Skill Builder (Max 6 Skills)
      </Typography>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Autocomplete
          value={newSkill}
          onChange={(_, value) => {
            if (value && typeof value !== 'string') {
              handleAddSkill(value);
            }
          }}
          options={recommendations}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Add a new skill"
              variant="outlined"
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <Typography variant="body1">{option.name}</Typography>
              <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
                ({option.category})
              </Typography>
            </li>
          )}
          sx={{ flexGrow: 1, mr: 2 }}
        />
        <Button onClick={fetchRecommendations} disabled={isLoading} variant="contained">
          Refresh Recommendations
        </Button>
      </Box>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="skills">
          {(provided) => (
            <Paper
              {...provided.droppableProps}
              ref={provided.innerRef}
              elevation={3}
              sx={{ p: 2, minHeight: 100 }}
            >
              <AnimatePresence>
                {skills.map((skill, index) => (
                  <Draggable key={skill.id} draggableId={skill.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <motion.div
                          layout
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.3 }}
                        >
                          <Chip
                            label={
                              <Box display="flex" alignItems="center">
                                <Typography variant="body2" sx={{ mr: 1 }}>
                                  {index + 1}.
                                </Typography>
                                <Typography variant="body2">{skill.name}</Typography>
                                <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
                                  ({skill.category})
                                </Typography>
                              </Box>
                            }
                            onDelete={() => handleDeleteSkill(skill.id)}
                            sx={{ m: 0.5 }}
                          />
                        </motion.div>
                      </div>
                    )}
                  </Draggable>
                ))}
              </AnimatePresence>
              {provided.placeholder}
            </Paper>
          )}
        </Droppable>
      </DragDropContext>
    </motion.div>
  );
};

export default SkillBuilder;