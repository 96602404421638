import React from 'react';
import { Paper, Typography, List, ListItem, ListItemText, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AgentData } from '@/types/AgentTypes';

const SummaryPanel = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  position: 'sticky',
  top: theme.spacing(2),
  maxHeight: 'calc(100vh - 100px)',
  overflowY: 'auto',
}));

interface AgentSummaryPanelProps {
  agentData: AgentData;
}

const AgentSummaryPanel: React.FC<AgentSummaryPanelProps> = ({ agentData }) => {
  return (
    <SummaryPanel elevation={3}>
      <Typography variant="h6" gutterBottom>Agent Summary</Typography>
      <List dense>
        <ListItem>
          <ListItemText primary="Name" secondary={agentData.name || 'Not set'} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Role" secondary={agentData.role || 'Not set'} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Voice" secondary={agentData.voice_id || 'Not selected'} />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="Skills" 
            secondary={
              <React.Fragment>
                {(() => {
                  try {
                    const skills = JSON.parse(agentData.skill || '[]');
                    return skills.map((skill: any, index: number) => (
                      <Chip key={index} label={skill.name || skill} size="small" style={{ margin: '2px' }} />
                    ));
                  } catch {
                    return <Chip label={agentData.skill} size="small" style={{ margin: '2px' }} />;
                  }
                })()}
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem>
  <ListItemText 
    primary="Personality Traits" 
    secondary={
      <React.Fragment>
        {(() => {
          try {
            const traits = JSON.parse(agentData.personality || '[]');
            return traits.map((trait: string, index: number) => (
              <Chip key={index} label={trait} size="small" style={{ margin: '2px' }} />
            ));
          } catch {
            return <Chip label={agentData.personality} size="small" style={{ margin: '2px' }} />;
          }
        })()}
      </React.Fragment>
    }
  />
</ListItem>
      </List>
    </SummaryPanel>
  );
};

export default AgentSummaryPanel;