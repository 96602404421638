import React from 'react';
import { Box, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { CloudUpload as CloudUploadIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { StyledPaper, UploadButton } from 'src/components/agentprofile/StyledComponents';
import { FileDetails } from 'src/types/AgentProfileTypes';

interface AgentKnowledgeBaseProps {
  files: FileDetails[];
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleDeleteFile: (fileId: string) => Promise<void>;
}

const AgentKnowledgeBase: React.FC<AgentKnowledgeBaseProps> = ({
  files,
  handleFileUpload,
  handleDeleteFile,
}) => {
  return (
    <StyledPaper>
      <Box sx={{ mb: 2 }}>
        <input
          accept="application/pdf"
          style={{ display: 'none' }}
          id="upload-file"
          type="file"
          onChange={handleFileUpload}
        />
        <label htmlFor="upload-file">
          <UploadButton
            component="span"
            startIcon={<CloudUploadIcon />}
            variant="contained"
            fullWidth
          >
            Upload Knowledge Base
          </UploadButton>
        </label>
      </Box>
      <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
        <List>
          {files.map((file) => (
            <ListItem key={file.id}>
              <ListItemText primary={file.name} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteFile(file.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
    </StyledPaper>
  );
};

export default AgentKnowledgeBase;