import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { AgentMetrics } from '@/types/AgentTypes';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

interface MetricsDisplayProps {
  metrics: AgentMetrics;
}

const MetricsDisplay: React.FC<MetricsDisplayProps> = ({ metrics }) => {
  const data = [
    { name: 'Total Calls', value: metrics.total_calls },
    { name: 'Successful Calls', value: metrics.success_rate * metrics.total_calls },
    { name: 'Average Duration', value: metrics.average_call_duration },
  ];

  return (
    <Grid container spacing={2}>
      {/* Metrics Summary */}
      <Grid item xs={12} md={4}>
        <Box>
          <Typography variant="h6">Total Calls</Typography>
          <Typography variant="h4">{metrics.total_calls}</Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h6">Success Rate</Typography>
          <Typography variant="h4">
            {(metrics.success_rate * 100).toFixed(2)}%
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h6">Avg Call Duration</Typography>
          <Typography variant="h4">
            {metrics.average_call_duration.toFixed(2)}s
          </Typography>
        </Box>
      </Grid>

      {/* Metrics Chart */}
      <Grid item xs={12} md={8}>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke="#3f51b5" />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

export default MetricsDisplay;
