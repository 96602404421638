import axios from 'axios';
import QuickLRU from 'quick-lru';

interface SkillRecommendation {
  skill: string;
  category: string;
  scenario: string;
}

const cache = new QuickLRU<string, SkillRecommendation[]>({ maxSize: 100 });

export const getSkillRecommendations = async (agentType: string, businessContext: string): Promise<SkillRecommendation[]> => {
  const cacheKey = `${agentType}-${businessContext}`;
  const cachedRecommendations = cache.get(cacheKey);

  if (cachedRecommendations) {
    return cachedRecommendations;
  }

  try {
    const apiKey = import.meta.env.VITE_OPENAI_API_KEY;
    if (!apiKey) {
      throw new Error('OpenAI API key is not set');
    }

    console.log('Using OpenAI API key:', apiKey);

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o',
        messages: [
          { role: 'system', content: 'You are a helpful assistant.' },
          {
            role: 'user',
            content: `Please provide a JSON array containing up to 5 skill recommendations for an AI Voice agent working in ${businessContext} as a ${agentType}. 
            The response should be a valid JSON array with no additional text, explanations, or markdown formatting. 
            Each object in the array should have three properties: 
            'skill' (a string representing the skill), 
            'category' (a string representing the category of the skill - choose from: Call Handling, Product Knowledge, Objection Handling, Empathy, Problem Solving, Technical Support, Sales Techniques, Customer Service, Industry Knowledge), 
            and 'scenario' (a brief example of how this skill would be used in a typical call).
            Make sure the response fits within 250 tokens.`,
          },
        ],
        max_tokens: 250,
        n: 1,
      },
      {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    let messageContent = response.data.choices[0].message.content.trim();

    console.log("Raw response content:", messageContent); // Debugging

    // Check if the response is a valid JSON array
    if (messageContent.startsWith('[') && messageContent.endsWith(']')) {
      const skills: SkillRecommendation[] = JSON.parse(messageContent);
      cache.set(cacheKey, skills);
      return skills;
    } else {
      throw new Error('Invalid response format or incomplete response');
    }
  } catch (error) {
    console.error('Error fetching skill recommendations:', error);
    return [];
  }
};