import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Slider, Grid, Paper, Tooltip, IconButton, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { motion } from 'framer-motion';
import { AgentData } from '@/types/AgentTypes';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, ChartTooltip, Legend);

interface PersonalityBuilderProps {
  agentData: AgentData;
  onChange: (newData: Partial<AgentData>) => void;
}

interface PersonalityTrait {
  name: string;
  description: string;
  automotiveExample: string;
}

const personalityTraits: PersonalityTrait[] = [
  {
    name: 'Friendliness',
    description: 'Approachability and warmth in interactions',
    automotiveExample: 'Greeting customers with a smile and engaging in light conversation about their day or interests'
  },
  {
    name: 'Professionalism',
    description: 'Maintaining a high standard of conduct and expertise',
    automotiveExample: 'Providing accurate information about vehicles and dealership policies without overselling'
  },
  {
    name: 'Enthusiasm',
    description: 'Showing genuine excitement and passion',
    automotiveExample: 'Expressing excitement about new vehicle features or sharing interesting facts about car models'
  },
  {
    name: 'Patience',
    description: 'Ability to remain calm and understanding',
    automotiveExample: 'Taking time to explain financing options or vehicle specifications without rushing the customer'
  },
  {
    name: 'Assertiveness',
    description: 'Confidence in guiding conversations and decisions',
    automotiveExample: 'Confidently recommending test drives or suggesting appropriate vehicles based on customer needs'
  },
  {
    name: 'Adaptability',
    description: 'Flexibility in approach based on customer needs',
    automotiveExample: 'Adjusting communication style for tech-savvy customers vs. those who prefer simpler explanations'
  },
  {
    name: 'Empathy',
    description: 'Understanding and relating to customer feelings',
    automotiveExample: 'Acknowledging customer concerns about budget constraints and finding suitable options'
  }
];

const PersonalityBuilder: React.FC<PersonalityBuilderProps> = ({ agentData, onChange }) => {
  const [traits, setTraits] = useState<number[]>(() => {
    if (agentData.personality) {
      try {
        const parsedTraits = JSON.parse(agentData.personality);
        if (Array.isArray(parsedTraits) && parsedTraits.length === personalityTraits.length) {
          return parsedTraits;
        }
      } catch (error) {
        console.error('Error parsing personality traits:', error);
      }
    }
    return personalityTraits.map(() => 50);
  });

  const updatePersonality = useCallback(() => {
    onChange({ personality: JSON.stringify(traits) });
  }, [traits, onChange]);

  useEffect(() => {
    updatePersonality();
  }, [updatePersonality]);

  const handleTraitChange = (index: number, value: number | number[]) => {
    const newTraits = [...traits];
    newTraits[index] = Array.isArray(value) ? value[0] : value;
    setTraits(newTraits);
  };

  const chartData = {
    labels: personalityTraits.map(trait => trait.name),
    datasets: [
      {
        label: 'Personality Traits',
        data: traits,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(75, 192, 192, 1)'
      },
    ],
  };

  const chartOptions = {
    scales: {
      r: {
        angleLines: {
          display: false
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          stepSize: 25
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h5" gutterBottom>
        Personality Builder
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {personalityTraits.map((trait, index) => (
            <motion.div
              key={trait.name}
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Box display="flex" alignItems="center" mb={1}>
                <Typography id={`trait-${index}`} variant="subtitle1">
                  {trait.name}
                </Typography>
                <Tooltip title={
                  <React.Fragment>
                    <Typography color="inherit">{trait.description}</Typography>
                    <Typography variant="body2">
                      Automotive example: {trait.automotiveExample}
                    </Typography>
                  </React.Fragment>
                }>
                  <IconButton size="small">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Slider
                value={traits[index]}
                onChange={(_, value) => handleTraitChange(index, value)}
                aria-labelledby={`trait-${index}`}
                valueLabelDisplay="auto"
                min={0}
                max={100}
                sx={{ mb: 2 }}
              />
            </motion.div>
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Radar data={chartData} options={chartOptions} />
          </Paper>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default PersonalityBuilder;