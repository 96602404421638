import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box, Toolbar, CircularProgress } from '@mui/material';
import AgentProfile from '../components/agentprofile/AgentProfile';
import VoiceSelection from '../components/VoiceSelection/VoiceSelection';
import PhoneNumberCreation from '../components/PhoneNumberCreation';
import { fetchUserAssistants, Assistant } from '@/api/api';
import { SidebarListWrapper } from '../components/SidebarListWrapper';

const MainLayout: React.FC = () => {
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { assistantId } = useParams<{ assistantId: string }>();
  const location = useLocation();

  useEffect(() => {
    const fetchAssistants = async () => {
      try {
        const data = await fetchUserAssistants();
        setAssistants(data);
      } catch (error) {
        console.error('Error fetching assistants:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAssistants();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const showSidebar = ['/agent', '/call-logs', '/files', '/voices', '/'].some(path => location.pathname.startsWith(path));

  return (
    <Box sx={{ display: 'flex' }}>
      {showSidebar && <SidebarListWrapper />}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {location.pathname === '/' && assistants.length > 0 && (
          <AgentProfile assistantId={assistants[0].assistant_id} />
        )}
        {assistantId && <AgentProfile assistantId={assistantId} />}
        {location.pathname === '/voices' && <VoiceSelection userId={''} />}
        {location.pathname === '/phone-numbers' && <PhoneNumberCreation onNumberCreated={() => {}} />}
      </Box>
    </Box>
  );
};

export default MainLayout;