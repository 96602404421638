import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import { ActivityLog } from '@/types/AgentTypes';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface RecentActivityProps {
  activities: ActivityLog[];
}

const RecentActivity: React.FC<RecentActivityProps> = ({ activities }) => {
  return (
    <List>
      {activities.map((activity, index) => (
        <React.Fragment key={activity.id}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccessTimeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={activity.action}
              secondary={new Date(activity.timestamp).toLocaleString()}
            />
          </ListItem>
          {index < activities.length - 1 && <Divider variant="inset" component="li" />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default RecentActivity;
