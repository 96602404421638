import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Paper,
  Grid,
  Chip,
  Tooltip,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Phone as PhoneIcon,
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { AgentData, PhoneNumber } from '@/types/AgentTypes';
import {
  fetchUserPhoneNumbers,
  assignPhoneNumber,
  unassignPhoneNumber,
  createPhoneNumber,
} from '@/api/api';

interface PhoneNumberAssignmentProps {
  agentData: AgentData;
  onChange: (newData: Partial<AgentData>) => void;
}

const PhoneNumberAssignment: React.FC<PhoneNumberAssignmentProps> = ({
  agentData,
  onChange,
}) => {
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<'success' | 'error'>('success');

  useEffect(() => {
    fetchPhoneNumbers();
  }, []);

  const fetchPhoneNumbers = async () => {
    try {
      const fetchedPhoneNumbers = await fetchUserPhoneNumbers();
      setPhoneNumbers(fetchedPhoneNumbers);
    } catch (error) {
      console.error('Error fetching phone numbers:', error);
    }
  };

  const handleCreatePhoneNumber = async () => {
    if (newPhoneNumber.trim() !== '') {
      try {
        if (agentData.assistant_id) {
          // Create and assign the new phone number
          const createdPhoneNumber = await createPhoneNumber(
            newPhoneNumber,
            agentData.assistant_id
          );

          // Refresh the phone numbers list
          await fetchPhoneNumbers();

          // Update agentData.phone_numbers
          const updatedAgentData = {
            ...agentData,
            phone_numbers: [
              ...(agentData.phone_numbers || []),
              createdPhoneNumber.number,
            ],
          };
          onChange(updatedAgentData);

          setNewPhoneNumber('');
          setIsDialogOpen(false);
          setSnackbarMessage('Phone number created and assigned successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        } else {
          throw new Error('Assistant ID is missing. Cannot create phone number.');
        }
      } catch (error) {
        console.error('Error creating and assigning phone number:', error);
        setSnackbarMessage(
          'Failed to create and assign phone number. Please try again.'
        );
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const handleAssignPhoneNumber = async (phoneNumberId: number) => {
    try {
      if (agentData.assistant_id) {
        await assignPhoneNumber(phoneNumberId, agentData.assistant_id);

        // Refresh the phone numbers list
        await fetchPhoneNumbers();

        const assignedPhoneNumber = phoneNumbers.find(
          (pn) => pn.id === phoneNumberId
        );
        if (assignedPhoneNumber) {
          const updatedAgentData = {
            ...agentData,
            phone_numbers: [
              ...(agentData.phone_numbers || []),
              assignedPhoneNumber.number,
            ],
          };
          onChange(updatedAgentData);
        }

        setSnackbarMessage('Phone number assigned successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        throw new Error('Assistant ID is missing. Cannot assign phone number.');
      }
    } catch (error) {
      console.error('Error assigning phone number:', error);
      setSnackbarMessage('Failed to assign phone number. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleUnassignPhoneNumber = async (phoneNumberId: number) => {
    try {
      await unassignPhoneNumber(phoneNumberId);

      // Refresh the phone numbers list
      await fetchPhoneNumbers();

      const unassignedPhoneNumber = phoneNumbers.find(
        (pn) => pn.id === phoneNumberId
      );
      if (unassignedPhoneNumber) {
        const updatedAgentData = {
          ...agentData,
          phone_numbers: (agentData.phone_numbers || []).filter(
            (num) => num !== unassignedPhoneNumber.number
          ),
        };
        onChange(updatedAgentData);
      }

      setSnackbarMessage('Phone number unassigned successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error unassigning phone number:', error);
      setSnackbarMessage('Failed to unassign phone number. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const assignedPhoneNumbers = phoneNumbers.filter(
    (pn) => pn.assistantId === agentData.assistant_id
  );
  const availablePhoneNumbers = phoneNumbers.filter(
    (pn) => pn.assistantId === null || pn.assistantId === ''
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Paper
        elevation={3}
        sx={{ p: 4, borderRadius: 2, bgcolor: 'background.default' }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{ display: 'flex', alignItems: 'center', mb: 3 }}
        >
          <PhoneIcon sx={{ mr: 1, color: 'primary.main' }} />
          Phone Number Assignment
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Available Phone Numbers
              </Typography>
              <List>
                <AnimatePresence>
                  {availablePhoneNumbers.map((number) => (
                    <motion.div
                      key={number.id}
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                    >
                      <ListItem>
                        <ListItemText primary={number.number} />
                        <ListItemSecondaryAction>
                          <Tooltip title="Assign to this agent">
                            <IconButton
                              edge="end"
                              onClick={() => handleAssignPhoneNumber(number.id)}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </List>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setIsDialogOpen(true)}
                sx={{ mt: 2, borderRadius: 20 }}
              >
                Create New Phone Number
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Assigned Phone Numbers
              </Typography>
              <List>
                <AnimatePresence>
                  {assignedPhoneNumbers.map((number) => (
                    <motion.div
                      key={number.id}
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                    >
                      <ListItem>
                        <ListItemText
                          primary={number.number}
                          secondary={
                            <Chip
                              label={`Assigned to ${agentData.name}`}
                              size="small"
                              color="primary"
                              sx={{ ml: 1 }}
                            />
                          }
                        />
                        <ListItemSecondaryAction>
                          <Tooltip title="Unassign from this agent">
                            <IconButton
                              edge="end"
                              onClick={() =>
                                handleUnassignPhoneNumber(number.id)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Create New Phone Number</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Phone Number"
            type="text"
            fullWidth
            variant="outlined"
            value={newPhoneNumber}
            onChange={(e) => setNewPhoneNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleCreatePhoneNumber} variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </motion.div>
  );
};

export default PhoneNumberAssignment;
